import React, { Fragment, useEffect } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Form,
} from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import {
  SIGN_DETAILS_SECTION,
  SIGN_STATUS,
  SIGN_STATUS_OPTION,
} from '../../SignDetails/constants/signDetailsConstants'
import { SIGN_WORKFLOW_STATUS } from '../../App/constants/appConstants'
import { useSignDetailsContext } from '../../SignDetails/context/signDetailsContext'
import SignBulkCheckbox from './SignBulkCheckbox'
import { useSignBulkEditContext } from '../context/signBulkEditContext'
import { useAppContext } from '../../App/context/appContext'
import { DropdownOption } from '../../../models/app/DropdownOption.model'
import { SignFacet } from '../../../models/signs/SignFacetsResponse.model'
import { getVendorOptions } from '../../App/helpers/vendorHelpers'
import { Department } from '../../../models/merchandise/hierarchy/Department.model'
import { useUserContext } from '../../App/context/userContext'
import SignResponse from '../../../models/signs/SignResponse.model'
import { isEditable } from '../../App/helpers/signEditHelper'

export interface Props {
  selectedStatus?: string
  selectedSigns: SignResponse[]
}

const SignBulkInfoSection = ({ selectedStatus = '', selectedSigns }: Props) => {
  const {
    signFacets,
    isInProject,
    expandedSection,
    setExpandedSection,
    modifiedSections,
    addToModifiedSections,
    refreshSignFacets,
    project,
  } = useSignDetailsContext()!
  const { bulkEditFields, addBulkEditField, selectedFields } =
    useSignBulkEditContext()!
  const { setPageHasChanges } = useAppContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!

  useEffect(() => {
    refreshSignFacets()
  }, [refreshSignFacets])

  const isDisabled = (field: string) => {
    if (selectedSigns && selectedSigns.length > 0) {
      for (var i = 0; i < selectedSigns.length; i++) {
        if (
          !isEditable(
            selectedSigns[i],
            field,
            userPermissions,
            userCompany,
            userType,
            isInProject,
            project.workflow.status,
            project.roster,
          )
        ) {
          return true
        }
      }
      return false
    }
    return true
  }

  const kitsFinalizedOrFurtherValues = [
    SIGN_WORKFLOW_STATUS.KITS_FINALIZED.toString(),
    SIGN_WORKFLOW_STATUS.COMPLETE.toString(),
    SIGN_WORKFLOW_STATUS.SENT_TO_ESS.toString(),
    SIGN_WORKFLOW_STATUS.SENT_TO_TPS.toString(),
  ]

  const kitsFinalizedOrFurther = selectedSigns.some((sign: SignResponse) =>
    kitsFinalizedOrFurtherValues.includes(sign.workflow.status),
  )

  const handleChange = (id: string, value: any) => {
    addBulkEditField(id, value)
    addToModifiedSections(SIGN_DETAILS_SECTION.INFO)
    setPageHasChanges(true)
  }

  const binarySelect = [
    new DropdownOption({ value: false, label: 'No' }),
    new DropdownOption({ value: true, label: 'Yes' }),
  ]

  return (
    <Fragment>
      <Grid.Item className="hc-pv-none hc-ph-normal" xs={12}>
        <ExpandableSection
          expanded={expandedSection === SIGN_DETAILS_SECTION.INFO}
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.INFO)
              ? 'modified'
              : ''
          }`}
          onExpand={() =>
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.INFO
                ? ''
                : SIGN_DETAILS_SECTION.INFO,
            )
          }
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey02" size={5}>
                Sign Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container align="center" className="sem_StandardForm">
                {((selectedStatus !== SIGN_STATUS.REISSUE &&
                  selectedStatus !== SIGN_STATUS.CARRY_FORWARD) ||
                  selectedSigns.every(
                    (sign: SignResponse) => sign.migrated,
                  )) && (
                  <Grid.Item xs={4}>
                    <Form.Field
                      type="select"
                      id="sign_type"
                      label="Sign Type"
                      rightContent={
                        <SignBulkCheckbox
                          fieldType="sign_type"
                          rightContent={true}
                          disabled={isDisabled('sign_type')}
                        />
                      }
                      disabled={!selectedFields.includes('sign_type')}
                      value={bulkEditFields.sign_type || ''}
                      onUpdate={(id: string, value: string) => {
                        handleChange(id, value)
                      }}
                      options={signFacets.sign_types}
                    />
                  </Grid.Item>
                )}
                <Grid.Item xs={8}>
                  <Grid.Container align="center">
                    {selectedStatus !== SIGN_STATUS.NEW &&
                      (!kitsFinalizedOrFurther ||
                        userPermissions.isSuperAdmin) && (
                        <Grid.Item xs={4}>
                          <Form.Field
                            type="select"
                            id="sign_status"
                            label="Sign Status"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="sign_status"
                                rightContent={true}
                                disabled={isDisabled('sign_status')}
                              />
                            }
                            value={bulkEditFields.sign_status || ''}
                            disabled={!selectedFields.includes('sign_status')}
                            options={SIGN_STATUS_OPTION}
                            onUpdate={(id: string, value: string) => {
                              handleChange(id, value)
                            }}
                          />
                        </Grid.Item>
                      )}
                    <Grid.Item xs={3}>
                      <SignBulkCheckbox
                        fieldType="on_pog_sign"
                        disabled={isDisabled('on_pog_sign')}
                      />
                      <Form.Field
                        id="on_pog_sign"
                        type="chip"
                        label="On POG Sign"
                        disabled={!selectedFields.includes('on_pog_sign')}
                        value={bulkEditFields.on_pog_sign}
                        onChange={(e: any) => {
                          handleChange(e.target.id, e.target.checked)
                        }}
                      />
                    </Grid.Item>
                    {!selectedSigns.some((sign) => {
                      return (
                        sign.distribution.locations_count === 0 &&
                        sign.sign_status !== SIGN_STATUS.CARRY_FORWARD
                      )
                    }) && (
                      <Grid.Item xs={4} className="hc-pr-none">
                        <SignBulkCheckbox
                          fieldType="spt_sign_on_pog"
                          disabled={isDisabled('spt_sign_on_pog')}
                        />
                        <Form.Field
                          id="spt_sign_on_pog"
                          type="chip"
                          label="SPT Sign on all POGs/POGs completed"
                          disabled={!selectedFields.includes('spt_sign_on_pog')}
                          value={bulkEditFields.spt_sign_on_pog}
                          onChange={(e: any) => {
                            handleChange(e.target.id, e.target.checked)
                          }}
                        />
                      </Grid.Item>
                    )}
                  </Grid.Container>
                </Grid.Item>
                {(selectedStatus !== SIGN_STATUS.CARRY_FORWARD ||
                  selectedSigns.every(
                    (sign: SignResponse) => sign.migrated,
                  )) && (
                  <Fragment>
                    {(selectedStatus !== SIGN_STATUS.REISSUE ||
                      selectedSigns.every(
                        (sign: SignResponse) => sign.migrated,
                      )) && (
                      <Fragment>
                        <Grid.Item xs={5}>
                          <Form.Field
                            type="select"
                            id="sign_creator"
                            label="Creation/Production Owner"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="sign_creator"
                                rightContent={true}
                                disabled={isDisabled('sign_creator')}
                              />
                            }
                            disabled={!selectedFields.includes('sign_creator')}
                            value={bulkEditFields.sign_creator || ''}
                            onUpdate={(e: any, value: string) => {
                              handleChange('sign_creator', value)
                            }}
                            options={signFacets.sign_creator.map(
                              (signCreator: SignFacet) =>
                                new DropdownOption<string>({
                                  id: signCreator.value,
                                  value: signCreator.value,
                                  label: signCreator.label,
                                }),
                            )}
                          />
                        </Grid.Item>
                        <Grid.Item xs={5}>
                          <Autocomplete
                            id="non_retail_item_info.product_vendor"
                            label="Product Vendor"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.product_vendor"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.product_vendor',
                                )}
                              />
                            }
                            options={(query: string) =>
                              getVendorOptions(
                                query,
                                (bulkEditFields.non_retail_item_info &&
                                  bulkEditFields.non_retail_item_info
                                    .product_vendor) ||
                                  '',
                              )
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.product_vendor',
                              )
                            }
                            value={{
                              value:
                                (bulkEditFields.non_retail_item_info &&
                                  bulkEditFields.non_retail_item_info
                                    .product_vendor) ||
                                '',
                              label:
                                (bulkEditFields.non_retail_item_info &&
                                  bulkEditFields.non_retail_item_info
                                    .product_vendor) ||
                                '',
                            }}
                            onUpdate={(id: string, value: any) => {
                              if (value) handleChange(id, value.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={4}>
                          <Form.Field
                            type="select"
                            id="department"
                            label="Department"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="department"
                                rightContent={true}
                                disabled={isDisabled('department')}
                              />
                            }
                            disabled={!selectedFields.includes('department')}
                            value={
                              (bulkEditFields.department &&
                                bulkEditFields.department.department_id) ||
                              ''
                            }
                            options={signFacets.departments.map(
                              (dept: Department) => ({
                                value: dept.department_id,
                                label: dept.department_name,
                              }),
                            )}
                            onUpdate={(id: string, value: number) => {
                              const selected = signFacets.departments.filter(
                                (department: Department) =>
                                  department.department_id === value,
                              )
                              handleChange(id, selected[0])
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={4}>
                          <Form.Field
                            type="select"
                            id="unit_of_measure"
                            label="Unit of Measure"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="unit_of_measure"
                                rightContent={true}
                                disabled={isDisabled('unit_of_measure')}
                              />
                            }
                            disabled={
                              !selectedFields.includes('unit_of_measure')
                            }
                            value={bulkEditFields.unit_of_measure || ''}
                            options={[
                              { value: 'EA', label: 'Each' },
                              { value: 'ST', label: 'Set' },
                              { value: 'KT', label: 'Kit' },
                            ]}
                            onUpdate={(id: string, value: string) => {
                              handleChange(id, value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={4}>
                          <Form.Field
                            type="number"
                            id="set_quantity"
                            label="Quantity per Set"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="set_quantity"
                                rightContent={true}
                                disabled={isDisabled('set_quantity')}
                              />
                            }
                            disabled={!selectedFields.includes('set_quantity')}
                            value={bulkEditFields.set_quantity || ''}
                            onChange={(e: any) => {
                              handleChange(
                                e.target.id,
                                parseInt(e.target.value),
                              )
                            }}
                            required={bulkEditFields.unit_of_measure === 'ST'}
                          />
                        </Grid.Item>
                        <Grid.Item xs={6}>
                          <Autocomplete
                            id="separator"
                            label="Separator"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="separator"
                                rightContent={true}
                                disabled={isDisabled('separator')}
                              />
                            }
                            options={(query: string) =>
                              getVendorOptions(
                                query,
                                bulkEditFields.separator || '',
                              )
                            }
                            disabled={!selectedFields.includes('separator')}
                            value={{
                              value: bulkEditFields.separator || '',
                              label: bulkEditFields.separator || '',
                            }}
                            onUpdate={(id: string, value: any) => {
                              if (value) handleChange(id, value.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={3}>
                          <Form.Field
                            type="select"
                            id="photography_needed"
                            label="Photography Needed"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="photography_needed"
                                rightContent={true}
                                disabled={isDisabled('photography_needed')}
                              />
                            }
                            disabled={
                              !selectedFields.includes('photography_needed')
                            }
                            value={bulkEditFields.photography_needed || ''}
                            options={binarySelect}
                            onUpdate={(id: string, value: string) => {
                              handleChange(id, value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.item_dimensions.width"
                            label="Finished Width"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.item_dimensions.width"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.item_dimensions.width',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.item_dimensions.width',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions.width) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.item_dimensions.height"
                            label="Finished Height"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.item_dimensions.height"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.item_dimensions.height',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.item_dimensions.height',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions.height) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.item_dimensions.depth"
                            label="Finished Depth"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.item_dimensions.depth"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.item_dimensions.depth',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.item_dimensions.depth',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .item_dimensions.depth) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.nominal_dimensions.width"
                            label="Flat Width"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.nominal_dimensions.width"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.nominal_dimensions.width',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.nominal_dimensions.width',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions.width) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.nominal_dimensions.height"
                            label="Flat Height"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.nominal_dimensions.height"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.nominal_dimensions.height',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.nominal_dimensions.height',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions.height) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                        <Grid.Item xs={2}>
                          <Form.Field
                            type="number"
                            id="non_retail_item_info.nominal_dimensions.depth"
                            label="Flat Depth"
                            rightContent={
                              <SignBulkCheckbox
                                fieldType="non_retail_item_info.nominal_dimensions.depth"
                                rightContent={true}
                                disabled={isDisabled(
                                  'non_retail_item_info.nominal_dimensions.depth',
                                )}
                              />
                            }
                            disabled={
                              !selectedFields.includes(
                                'non_retail_item_info.nominal_dimensions.depth',
                              )
                            }
                            value={
                              (bulkEditFields.non_retail_item_info &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions &&
                                bulkEditFields.non_retail_item_info
                                  .nominal_dimensions.depth) ||
                              ''
                            }
                            onChange={(e: any) => {
                              handleChange(e.target.id, e.target.value)
                            }}
                          />
                        </Grid.Item>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Grid.Container>
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
      <Grid.Item className="hc-mv-dense" xs={12}>
        <Divider />
      </Grid.Item>
    </Fragment>
  )
}

export default SignBulkInfoSection
