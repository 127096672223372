import React, { Fragment, useEffect, useMemo } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Form,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { useSignBulkEditContext } from '../../SignBulkEdit/context/signBulkEditContext'
import SignBulkCheckbox from '../../SignBulkEdit/components/SignBulkCheckbox'
import { SIGN_DETAILS_SECTION } from '../constants/signDetailsConstants'
import { useUserContext } from '../../App/context/userContext'
import { useSignDetailsContext } from '../context/signDetailsContext'
import { useSignFormsContext } from '../context/signFormsContext'
import { useAppContext } from '../../App/context/appContext'
import { isEditable } from '../../App/helpers/signEditHelper'
import SignResponse from '../../../models/signs/SignResponse.model'

export interface Props {
  isBulkEdit?: boolean
  selectedSigns?: SignResponse[]
}

const SignPricingSection = ({ isBulkEdit = false, selectedSigns }: Props) => {
  const { setPageHasChanges } = useAppContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!
  const {
    isInProject,
    currentSign,
    expandedSection,
    setExpandedSection,
    modifiedSections,
    addToModifiedSections,
    project,
  } = useSignDetailsContext()!
  const { signPricingFormik } = useSignFormsContext()!
  const { addBulkEditField, selectedFields } = useSignBulkEditContext()!

  const handleChange = (id: string, value: string) => {
    if (isBulkEdit) addBulkEditField('pricing.' + id, value)
    signPricingFormik.setFieldValue(id, value)
    addToModifiedSections(SIGN_DETAILS_SECTION.PRICING)
    setPageHasChanges(true)
  }

  const isDisabled = (field: string) => {
    if (isBulkEdit) {
      if (selectedSigns && selectedSigns.length > 0) {
        for (var i = 0; i < selectedSigns.length; i++) {
          if (
            !isEditable(
              selectedSigns[i],
              field,
              userPermissions,
              userCompany,
              userType,
              isInProject,
              project.workflow.status,
            )
          ) {
            return true
          }
        }
        return false
      }
      return true
    } else {
      return !isEditable(
        currentSign,
        field,
        userPermissions,
        userCompany,
        userType,
        isInProject,
        project.workflow.status,
      )
    }
  }

  const isVDVPCreator = useMemo(() => {
    return currentSign.sign_creator === 'Vendor Designed/Vendor Produced'
  }, [currentSign])

  useEffect(() => {
    if (isVDVPCreator) {
      signPricingFormik.setFieldValue('final_unit_price', 0)
    }
  }, [isVDVPCreator]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Fragment>
      <Grid.Item className="hc-pv-none hc-ph-normal" xs={12}>
        <ExpandableSection
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.PRICING)
              ? 'modified'
              : ''
          }`}
          expanded={expandedSection === SIGN_DETAILS_SECTION.PRICING}
          onExpand={() =>
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.PRICING
                ? ''
                : SIGN_DETAILS_SECTION.PRICING,
            )
          }
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-pl-normal" size={5}>
                Pricing & Estimation Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container
                className={`sem_StandardForm ${
                  isInProject ? 'hc-mb-normal error_messaging_enabled' : ''
                }`}
              >
                <Grid.Item xs={6}>
                  <Form.Field
                    id="estimated_unit_price"
                    label="Estimated Unit Price"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="pricing.estimated_unit_price"
                          rightContent={true}
                          disabled={isDisabled('estimated_unit_price')}
                        />
                      )
                    }
                    value={
                      !isNaN(
                        signPricingFormik.values.estimated_unit_price ||
                          (undefined as any),
                      )
                        ? signPricingFormik.values.estimated_unit_price
                        : ''
                    }
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes(
                            'pricing.estimated_unit_price',
                          )
                        : isDisabled('estimated_unit_price')
                    }
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="estimated_quantity"
                    label="Estimated Quantity"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="pricing.estimated_quantity"
                          rightContent={true}
                          disabled={isDisabled('estimated_quantity')}
                        />
                      )
                    }
                    value={signPricingFormik.values.estimated_quantity || ''}
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('pricing.estimated_quantity')
                        : isDisabled('estimated_quantity')
                    }
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="final_unit_price"
                    label="Final Unit Price"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="pricing.final_unit_price"
                          rightContent={true}
                          disabled={isDisabled('final_unit_price')}
                        />
                      )
                    }
                    value={
                      !isNaN(
                        signPricingFormik.values.final_unit_price ||
                          (undefined as any),
                      )
                        ? signPricingFormik.values.final_unit_price
                        : ''
                    }
                    disabled={
                      isVDVPCreator || isBulkEdit
                        ? !selectedFields.includes('pricing.final_unit_price')
                        : isDisabled('final_unit_price')
                    }
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <Form.Field
                    id="po_number"
                    label="PO #"
                    rightContent={
                      isBulkEdit && (
                        <SignBulkCheckbox
                          fieldType="pricing.po_number"
                          rightContent={true}
                          disabled={isDisabled('po_number')}
                        />
                      )
                    }
                    value={signPricingFormik.values.po_number || ''}
                    disabled={
                      isBulkEdit
                        ? !selectedFields.includes('pricing.po_number')
                        : isDisabled('po_number')
                    }
                    onChange={(e: any) =>
                      handleChange(e.target.id, e.target.value)
                    }
                  />
                </Grid.Item>
                {isInProject && <Divider className="hc-pa-dense" />}
              </Grid.Container>
              {isInProject &&
                !isBulkEdit &&
                (userPermissions.isSignEditor ||
                  currentSign.separator === userCompany) && (
                  <Grid.Container
                    className="hc-mt-normal"
                    direction="row-reverse"
                  >
                    <Grid.Item className="hc-pb-dense hc-pt-normal">
                      <Button
                        fullWidth
                        type="primary"
                        size="dense"
                        disabled={
                          !modifiedSections.includes(
                            SIGN_DETAILS_SECTION.PRICING,
                          )
                        }
                        onClick={() => signPricingFormik.handleSubmit()}
                      >
                        Save Changes
                      </Button>
                    </Grid.Item>
                  </Grid.Container>
                )}
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
    </Fragment>
  )
}

export default SignPricingSection
