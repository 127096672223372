import React from 'react'
import { Grid, Button, Dropdown } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { CaretDownIcon } from '@enterprise-ui/icons'
import { useUserContext } from 'components/App/context/userContext'
import { useAppContext } from 'components/App/context/appContext'
import { useProjectDetailsContext } from '../../../../../context/projectDetailsContext'
import {
  STANDARD_VIEW,
  ESTIMATION_VIEW,
  PRINT_VIEW,
  IPP_DEMAND_VIEW,
  PRINT_VENDOR_DEMAND_VIEW,
  FINALIZATION_VIEW,
  SPT_VIEW,
  SPT_SPLN_VIEW,
  SEPARATOR_AGENCY_VIEW,
  IRL_VIEW,
  SPLN_VIEW,
  DISPLAY_SIGN_VIEW,
  VC_VIEW,
  CREATIVE_AGENCY_VIEW,
} from '../../../views/AgGridSignListViews'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { DialogProps } from '../../../../../../../models/app/DialogProps.model'
import { AgGridSignListView } from '../../../../../../../models/agGrid/AgGridSignListView.model'

const ChangeViewButton = () => {
  const { userPermissions } = useUserContext()!
  const { projectSignListView, setProjectSignListView, setDialogProps } =
    useAppContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { signsModified } = useProjectSignListContext()!

  const handleViewChange = (view: AgGridSignListView) => {
    if (signsModified) {
      setDialogProps({
        headingText:
          'Changing view while editing may result in lost or erroneous data.',
        approveButtonText: 'Ok',
        onApprove: () => {
          setProjectSignListView(view)
          setDialogProps(new DialogProps())
        },
        onRefuse: () => {
          setDialogProps(new DialogProps())
        },
      })
    } else {
      setProjectSignListView(view)
    }
  }

  return (
    <Grid.Item className="hc-ph-dense hc-pb-dense">
      <Grid.Container>
        <Grid.Item>
          <Dropdown location="bottom-left" size="dense">
            <Button
              type="ghost"
              data-testid="openChangeView"
              disabled={
                userPermissions.isPrintVendor || userPermissions.isKitVendor
              }
            >
              View: {projectSignListView.label}
              <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
            </Button>
            <Dropdown.Menu>
              <Dropdown.MenuItem
                data-testid="openStandardView"
                onClick={() => handleViewChange(STANDARD_VIEW)}
              >
                Standard View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openFinalizationView"
                onClick={() => handleViewChange(FINALIZATION_VIEW)}
              >
                Finalization View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openEstimationView"
                onClick={() => handleViewChange(ESTIMATION_VIEW)}
              >
                Estimation View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openSptView"
                onClick={() => {
                  currentProject.project_type === 'SPLN'
                    ? handleViewChange(SPT_SPLN_VIEW)
                    : handleViewChange(SPT_VIEW)
                }}
              >
                SPT View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openVcView"
                onClick={() => handleViewChange(VC_VIEW)}
              >
                VC View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openPrintView"
                onClick={() => handleViewChange(PRINT_VIEW)}
              >
                Print View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openIppView"
                onClick={() => handleViewChange(IPP_DEMAND_VIEW)}
              >
                IPP Demand View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openPrintVendorView"
                onClick={() => handleViewChange(PRINT_VENDOR_DEMAND_VIEW)}
              >
                Print Vendor Demand View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openSeparatorView"
                onClick={() => handleViewChange(SEPARATOR_AGENCY_VIEW)}
              >
                Separator View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openIrlView"
                onClick={() => handleViewChange(IRL_VIEW)}
              >
                IRL View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openSplnView"
                onClick={() => handleViewChange(SPLN_VIEW)}
              >
                SPLN View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openDisplaySignView"
                onClick={() => handleViewChange(DISPLAY_SIGN_VIEW)}
              >
                Display Sign View
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                data-testid="openAgencyView"
                onClick={() => handleViewChange(CREATIVE_AGENCY_VIEW)}
              >
                Agency View
              </Dropdown.MenuItem>
            </Dropdown.Menu>
          </Dropdown>
        </Grid.Item>
      </Grid.Container>
    </Grid.Item>
  )
}

export default ChangeViewButton
