import React, { Fragment, useEffect } from 'react'
import {
  Grid,
  Tabs,
  Divider,
  Popover,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import { TabName } from '../constants/dashboardConstants'
import { useUserContext } from '../../App/context/userContext'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import DashboardRosterCard from './OverviewCard/DashboardRosterCard'
import ProjectManagerSection from './OverviewCard/ProjectManagerSection'
import BlueprintDashboard from './BlueprintSummary/BlueprintDashboard'
import ProjectDashboard from './ProjectSummary/ProjectDashboard'
import { useDashboardContext } from '../context/dashboardContext'
import EnterpriseIcon, { CautionIcon } from '@enterprise-ui/icons'
import { useAppContext } from '../../App/context/appContext'
import { DialogProps } from '../../../models/app/DialogProps.model'

const Dashboard = () => {
  const { userPermissions } = useUserContext()!

  const {
    //pageHasChanges,
    setDialogProps,
    dashboardActiveTab,
    setDashboardActiveTab,
  } = useAppContext()!
  const {
    managers,
    setManagers,
    blueprintSummaryList,
    getBlueprintSummaryList,
    getBlueprintMilestoneSummaryList,
    projectSummaryList,
    getProjectSummaryList,
    getProjectMilestoneSummaryList,
    showProjectMilestonAlert,
    getColorCount,
    showKitAlert,
    includeCompleteBlueprints,
    includeAllActiveBlueprints,
    includeCompleteProjects,
    includeAllActiveProjects,
    includeCompleteBpMilestones,
    includeCompleteProjectMilestones,
  } = useDashboardContext()!

  const showAllTabs =
    !userPermissions.isPrintVendor &&
    !userPermissions.isKitVendor &&
    !userPermissions.isProductVendorOrCatMan

  useEffect(() => {
    if (showAllTabs) {
      getBlueprintSummaryList(
        includeCompleteBlueprints,
        includeAllActiveBlueprints,
      )
    }
  }, [
    showAllTabs,
    getBlueprintSummaryList,
    includeCompleteBlueprints,
    includeAllActiveBlueprints,
  ])

  useEffect(() => {
    if (showAllTabs) {
      getBlueprintMilestoneSummaryList(includeCompleteBpMilestones)
    }
  }, [
    showAllTabs,
    getBlueprintMilestoneSummaryList,
    includeCompleteBpMilestones,
  ])

  useEffect(() => {
    getProjectSummaryList(includeCompleteProjects, includeAllActiveProjects)
  }, [getProjectSummaryList, includeCompleteProjects, includeAllActiveProjects])

  useEffect(() => {
    if (showAllTabs) {
      getProjectMilestoneSummaryList(includeCompleteProjectMilestones)
    }
  }, [
    showAllTabs,
    getProjectMilestoneSummaryList,
    includeCompleteProjectMilestones,
  ])

  return (
    <Fragment>
      <MainSectionHeader sectionTitle="My Dashboard" />
      <Grid.Container direction="column" spacing="dense">
        <Grid.Item className="hc-pa-none">
          <Card className="hc-ph-normal hc-pv-md sem_DashboardSummary">
            <Grid.Container align="center">
              <Grid.Item>
                <Grid.Container align="center">
                  <Grid.Item>
                    <DashboardRosterCard
                      users={managers}
                      handleDelete={(email: string) => {
                        setManagers(
                          managers.filter((manager) => manager.email !== email),
                        )
                        setDialogProps(new DialogProps())
                      }}
                      handleAdd={(email: string) => {
                        setManagers([
                          ...managers,
                          { email, isSessionUser: false },
                        ])
                      }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </Grid.Item>
              {showAllTabs ? (
                <Grid.Item>
                  <ProjectManagerSection
                    name="Active Blueprints"
                    greenCount={getColorCount(blueprintSummaryList, 'green')}
                    yellowCount={getColorCount(blueprintSummaryList, 'yellow')}
                    redCount={getColorCount(blueprintSummaryList, 'red')}
                    greyCount={getColorCount(blueprintSummaryList, 'grey')}
                    blueCount={getColorCount(blueprintSummaryList, 'blue')}
                  />
                </Grid.Item>
              ) : null}
              <Grid.Item>
                <ProjectManagerSection
                  name="Active Projects"
                  greenCount={getColorCount(projectSummaryList, 'green')}
                  yellowCount={getColorCount(projectSummaryList, 'yellow')}
                  redCount={getColorCount(projectSummaryList, 'red')}
                  greyCount={getColorCount(projectSummaryList, 'grey')}
                  blueCount={getColorCount(projectSummaryList, 'blue')}
                />
              </Grid.Item>
            </Grid.Container>
          </Card>
        </Grid.Item>
        <Card className="hc-pa-dense hc-mt-dense">
          <Grid.Item>
            <Tabs
              className="hc-mb-none"
              onTabSelect={(event: any, tab: any) => {
                setDashboardActiveTab(tab.name)
              }}
              activeTab={dashboardActiveTab}
              disableAdaptive={true}
            >
              <Tabs.Item name={TabName.MY_BLUEPRINT_MILESTONES}>
                My Blueprint Milestones
              </Tabs.Item>
              <Tabs.Item name={TabName.MY_PROJECT_MILESTONES}>
                My Project Milestones
                {showProjectMilestonAlert ? (
                  <Popover
                    content={
                      <p>Some Milestones are in need of your attention.</p>
                    }
                    location="bottom"
                  >
                    <EnterpriseIcon
                      className="hc-ml-dense sem-kitting-alert"
                      color="yellow"
                      icon={CautionIcon}
                    />
                  </Popover>
                ) : null}
              </Tabs.Item>
              <Tabs.Item name={TabName.BLUEPRINT_SUMMARY}>
                Blueprint Summary
              </Tabs.Item>
              <Tabs.Item name={TabName.PROJECT_SUMMARY}>
                Project Summary
                {showKitAlert ? (
                  <Popover
                    content={
                      <p>
                        Some projects need kits recreation due to sign updates.
                      </p>
                    }
                    location="bottom"
                  >
                    <EnterpriseIcon
                      className="hc-ml-dense sem-kitting-alert"
                      color="yellow"
                      icon={CautionIcon}
                    />
                  </Popover>
                ) : null}
              </Tabs.Item>
            </Tabs>
            <Divider className="hc-mb-normal" />
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pt-none">
            <Fragment>
              {dashboardActiveTab === TabName.MY_BLUEPRINT_MILESTONES ||
              dashboardActiveTab === TabName.BLUEPRINT_SUMMARY ? (
                <BlueprintDashboard />
              ) : null}
              {dashboardActiveTab === TabName.MY_PROJECT_MILESTONES ||
              dashboardActiveTab === TabName.PROJECT_SUMMARY ? (
                <ProjectDashboard />
              ) : null}
            </Fragment>
          </Grid.Item>
        </Card>
      </Grid.Container>
    </Fragment>
  )
}

export default Dashboard
