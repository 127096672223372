import React from 'react'
import { Grid, Card, Anchor, Caption } from '@enterprise-ui/canvas-ui-react'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { getHealthChipColor } from 'components/App/helpers/userColor'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useUserContext } from 'components/App/context/userContext'
import { getProjectStatus } from 'components/App/helpers/signEditHelper'
import RubixChip from 'components/common/RubixChip'

export const ProjectOverviewCard = () => {
  const { userInfo } = useUserContext()!
  const { currentProject, currentProjectSummary } = useProjectDetailsContext()!

  return (
    <Grid.Item xs={12}>
      <Card className="sem_OverviewCard" elevation={0}>
        <div className="hc-pa-normal">
          <Grid.Container
            className="sem_Overview"
            align="center"
            justify="space-between"
          >
            <Grid.Item className="hc-pt-none">
              <Caption uppercase above="Set Date " className="hc-pt-normal">
                {currentProject.set_date &&
                moment(currentProject.set_date).isValid()
                  ? moment(currentProject.set_date).format('MM/DD/YYYY')
                  : ''}
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <Caption uppercase above="Blueprint" className="hc-pt-normal">
                {userInfo.permissions.isKitVendor ||
                userInfo.permissions.isPrintVendor ||
                userInfo.permissions.isProductVendorOrCatMan ? (
                  currentProject?.blueprint_name
                ) : (
                  <Anchor
                    className="sem_StrongText hc-mr-dense"
                    as={Link}
                    to={`/blueprints/${currentProject?.blueprint_id}`}
                  >
                    {currentProject?.blueprint_name}
                  </Anchor>
                )}
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <Caption uppercase above="SAP PID" className="hc-pt-normal">
                {currentProject?.sap_project_id
                  ? currentProject?.sap_project_id
                  : 'None'}
              </Caption>
            </Grid.Item>
            <Grid.Item className="hc-pt-none">
              <Caption
                uppercase
                above="Project Status"
                className="hc-pt-normal"
              >
                <RubixChip
                  data-testid="statusChip"
                  color={
                    currentProject.workflow.color === 'warning'
                      ? 'yellow'
                      : currentProject.workflow.color
                  }
                >
                  {getProjectStatus(currentProject)}
                </RubixChip>
              </Caption>
            </Grid.Item>
            {currentProjectSummary.current_milestone && (
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Current Phase"
                  className="hc-pt-normal"
                >
                  <RubixChip
                    data-testid="statusChip"
                    color={getHealthChipColor(currentProjectSummary.health)}
                  >
                    {currentProjectSummary.current_milestone.project_phase ||
                      'None'}
                  </RubixChip>
                </Caption>
              </Grid.Item>
            )}
            {currentProjectSummary.current_milestone && (
              <Grid.Item className="hc-pt-none">
                <Caption
                  uppercase
                  above="Current Milestone"
                  className="hc-pt-normal"
                >
                  <RubixChip
                    data-testid="statusChip"
                    color={getHealthChipColor(currentProjectSummary.health)}
                  >
                    {currentProjectSummary.current_milestone.name || 'None'}
                  </RubixChip>
                </Caption>
              </Grid.Item>
            )}
          </Grid.Container>
        </div>
      </Card>
    </Grid.Item>
  )
}

export default ProjectOverviewCard
