import React, { useCallback, useEffect, useState } from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import {
  CellValueChangedEvent,
  ColumnMovedEvent,
  FilterChangedEvent,
  RowDragEvent,
  SelectionChangedEvent,
  SortChangedEvent,
} from 'ag-grid-community'
import ActionsDropdown from './components/ActionsDropdown'
import ChangeViewButton from './components/ChangeViewButton'
import TabelEditorButtons from './components/TabelEditorButtons'
import SignCountDropdown from './components/SignCountDropdown'
import {
  filteredSignListByView,
  orderSignListByStatus,
  isUpdatedWithinDay,
} from '../../helpers/signListHelper'
import { Column } from '../../../../../../models/agGrid/AgGridColumn.model'
import {
  CREATIVE_AGENCY_VIEW,
  PRINT_VENDOR_DEMAND_VIEW,
  SEPARATOR_AGENCY_VIEW,
  SPT_VIEW,
} from '../../views/AgGridSignListViews'
import { useProjectSignListContext } from '../../context/projectSignListContext'
import { useUserContext } from '../../../../../App/context/userContext'
import { useAppContext } from '../../../../../App/context/appContext'
import { useProjectDetailsContext } from '../../../../context/projectDetailsContext'
import { isCategoryManager } from '../../../../../App/helpers/isCategoryManager'
import { useMountEffect } from '../../../../../App/hooks/useMountEffect'
import { useAgGridSignListContext } from '../../context/agGridSignListContext'
import StatusChip from './components/StatusChip'
import { isCreativeAgent } from '../../../../../App/components/isCreativeAgent'
import { loadColumnState, saveColumnState } from 'utils/agGrid'

export const LS_KEY = 'AgGridSignListTable'

const AgGridSignListTable = () => {
  const { userPermissions, userCompany } = useUserContext()!
  const { projectSignListView, setProjectSignListView } = useAppContext()!
  const { currentProject } = useProjectDetailsContext()!
  const { projectSignList, isTableEditorFullScreen } =
    useProjectSignListContext()!
  const {
    rowData,
    setRowData,
    onGridReady,
    gridOptions,
    onFirstDataRender,
    onSelectionChange,
    onCellValueChange,
    onRowDragEnd,
    onFilterChange,
    onSortChanged,
    fillOperation,
  } = useAgGridSignListContext()!

  const rowClassRules = {
    'ag-row-highlight': (params: any) => {
      return isUpdatedWithinDay(params.data?.updated_date)
    },
  }

  const selectViewByUser = () => {
    if (userPermissions.isPrintVendor || userPermissions.isKitVendor) {
      return PRINT_VENDOR_DEMAND_VIEW
    } else if (isCreativeAgent(currentProject.roster, userCompany)) {
      return CREATIVE_AGENCY_VIEW
    } else if (userPermissions.isAgencySeparator) {
      return SEPARATOR_AGENCY_VIEW
    } else if (isCategoryManager(currentProject.roster, userCompany)) {
      return SPT_VIEW
    } else {
      return projectSignListView
    }
  }

  useMountEffect(() => {
    setProjectSignListView(selectViewByUser())
  })

  const [, update] = useState(0)

  const forceUpdate = useCallback(
    (cb) => (params: ColumnMovedEvent) => {
      cb(params)
      update((n) => ++n)
    },
    [update],
  )

  useEffect(() => {
    setRowData(
      filteredSignListByView(
        projectSignListView,
        projectSignList,
        userPermissions,
      ),
    )
  }, [projectSignListView, projectSignList, userPermissions, setRowData])

  return (
    <Grid.Container
      className="hc-pa-normal hc-mt-dense hc-mr-dense"
      justify="space-between"
      align="center"
    >
      <ActionsDropdown />
      <ChangeViewButton />
      <StatusChip />
      <TabelEditorButtons />
      <SignCountDropdown signList={rowData} />
      <Grid.Item xs={12} className="hc-pt-none">
        <div
          className={`ag-theme-rubix project-sign-list-table ${
            isTableEditorFullScreen && 'full-screen'
          }`}
        >
          <AgGridReact
            gridOptions={gridOptions}
            onColumnMoved={forceUpdate(saveColumnState(LS_KEY))}
            onGridReady={loadColumnState(LS_KEY, onGridReady)}
            onFirstDataRendered={onFirstDataRender}
            rowData={orderSignListByStatus(rowData)}
            rowClassRules={rowClassRules}
            groupDisplayType="groupRows"
            groupDefaultExpanded={1}
            groupRowRendererParams={{ checkbox: true }}
            groupSelectsChildren
            groupSelectsFiltered
            rowDragMultiRow
            suppressRowClickSelection
            rowSelection="multiple"
            enableRangeSelection
            enableFillHandle
            fillHandleDirection="y"
            onRowDragEnd={(e: RowDragEvent) => onRowDragEnd(e)}
            onFilterChanged={(e: FilterChangedEvent) => onFilterChange(e)}
            onSortChanged={(e: SortChangedEvent) => onSortChanged(e)}
            onSelectionChanged={(e: SelectionChangedEvent) =>
              onSelectionChange(e)
            }
            onCellValueChanged={(e: CellValueChangedEvent) =>
              onCellValueChange(e)
            }
            fillOperation={fillOperation}
          >
            <AgGridColumn field="group" rowGroup hide />
            {projectSignListView &&
              projectSignListView.columns.map(
                (column: Column, index: number) => (
                  <AgGridColumn
                    field={column.field}
                    headerName={column.headerName}
                    width={column.width || 148}
                    type={column.type}
                    filter={column.filter || 'agTextColumnFilter'}
                    colId={column.colId}
                    key={index}
                  />
                ),
              )}
          </AgGridReact>
        </div>
      </Grid.Item>
    </Grid.Container>
  )
}

export default AgGridSignListTable
