import React, { Fragment } from 'react'
import { Grid, Dropdown, Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CancelIcon,
  CaretDownIcon,
  DownloadIcon,
  MaximizeIcon,
  MinimizeIcon,
  RedoIcon,
  SaveIcon,
  UndoIcon,
} from '@enterprise-ui/icons'
import { useAppContext } from '../../App/context/appContext'
import { useFilterContext } from '../../App/context/filterContext'
import { useAgGridSignLibraryContext } from '../context/agGridSignLibraryContext'
import { SIGN_LIBRARY_VIEW } from '../../App/constants/appConstants'
import {
  SIGN_LIBRARY_STANDARD_VIEW,
  SIGN_LIBRARY_PRINT_VIEW,
} from '../views/AgGridSignLibraryView'
import { useExportOptionsContext } from '../context/exportOptionsContext'
import { LockColumnsButton } from 'components/common/LockColumnsButton'
import { SL_LS_KEY } from './SignLibraryTable'
import { columnStateIsLocked } from 'utils/agGrid'

const SignLibraryButtonRow = () => {
  const { signLibraryView, setSignLibraryView } = useAppContext()!
  const { setSignLibraryFilterModel } = useFilterContext()!
  const {
    signsModified,
    setSignsModified,
    setModifiedSigns,
    setModifiedSignIds,
    saveModifiedSigns,
    resetAllFilters,
    refreshDatasource,
  } = useAgGridSignLibraryContext()!
  const { exportOpen, setExportOpen } = useExportOptionsContext()!

  const handleResetFilters = () => {
    setSignLibraryFilterModel({})
    resetAllFilters()
  }

  const handleSave = () => {
    saveModifiedSigns()
  }

  const handleCancel = () => {
    setModifiedSigns([])
    setModifiedSignIds([])
    setSignsModified(false)
    refreshDatasource()
  }

  const handleExport = () => {
    setExportOpen(true)
  }

  return (
    <Fragment>
      <Grid.Item className="hc-ph-normal">
        <Dropdown size="normal" location="bottom-left">
          <Button type="ghost">
            Actions
            <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              disabled={!columnStateIsLocked(SL_LS_KEY)}
            >
              <LockColumnsButton lsKey={SL_LS_KEY} />
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              disabled={exportOpen}
              onClick={() => handleExport()}
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={DownloadIcon}
              />
              Export as Excel File
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => handleResetFilters()}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={UndoIcon}
              />
              Reset All Sign Filters
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => {}}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={MinimizeIcon}
              />
              Size Columns to Fit
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => {}}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={MaximizeIcon}
              />
              Auto Size All Columns
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => {}}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={RedoIcon}
              />
              Reset Column Sizes
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense" xs={true}>
        <Dropdown size="normal" location="bottom-left">
          <Button type="ghost">
            View: {signLibraryView.label}
            <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem
              onClick={() => setSignLibraryView(SIGN_LIBRARY_STANDARD_VIEW)}
            >
              {`${SIGN_LIBRARY_VIEW.STANDARD} View`}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              onClick={() => setSignLibraryView(SIGN_LIBRARY_PRINT_VIEW)}
            >
              {`${SIGN_LIBRARY_VIEW.PRINT_INFO} View`}
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signsModified}
          onClick={() => handleCancel()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={CancelIcon} />
          Cancel Changes
        </Button>
      </Grid.Item>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Button
          type="ghost"
          disabled={!signsModified}
          onClick={() => handleSave()}
        >
          <EnterpriseIcon className="hc-mr-dense" icon={SaveIcon} />
          Save Changes
        </Button>
      </Grid.Item>
    </Fragment>
  )
}

export default SignLibraryButtonRow
