export enum TabName {
  DETAILS = 'DETAILS',
  SIGN_LIST = 'SIGN LIST',
  TIMELINE = 'TIMELINE',
  KITTING = 'KITTIING/DISTRIBUTION',
}

export enum ProjectStatus {
  KITS_FINALIZED = 'KITS FINALIZED',
  KITS_RECREATE_AFTER_FINALIZE = 'KITS RECREATE AFTER FINALIZE',
  KITS_RECREATE = 'KITS RECREATE',
  ARCHIVE = 'ARCHIVE',
  READY = 'READY',
  CANCEL = 'CANCEL',
}
