import {
  DISPLAY_SIGN_VIEW,
  ESTIMATION_VIEW,
  FINALIZATION_VIEW,
  IPP_DEMAND_VIEW,
  IRL_VIEW,
  PRINT_VENDOR_DEMAND_VIEW,
  PRINT_VIEW,
  SEPARATOR_AGENCY_VIEW,
  SPLN_VIEW,
  SPT_VIEW,
  SPT_SPLN_VIEW,
  STANDARD_VIEW,
  VC_VIEW,
  CREATIVE_AGENCY_VIEW,
} from '../views/AgGridSignListViews'
import { AgGridSignListView } from '../../../../../models/agGrid/AgGridSignListView.model'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { get, orderBy, parseInt, set } from 'lodash'
import { UserPermissions } from '../../../../../models/app/UserPermissions.model'
import { TableEditedSign } from '../../../../../models/signs/TableEditorRequest.model'
import TableEditorFields from '../../../../../models/signs/TableEditorFields.model'
import {
  RowClassParams,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import moment from 'moment'
import { POST_KIT_FINALIZATION } from '../../../../App/constants/appConstants'
import { SIGN_SIZE_FACETS } from '../../../../SignDetails/constants/signMessagingFacets'

export const filteredSignListByView = (
  signListView: AgGridSignListView,
  signList: SignResponse[],
  userPermission: UserPermissions,
) => {
  /* === Filter out New signs for Agency view === */
  if (signListView === CREATIVE_AGENCY_VIEW) {
    return signList.filter((sign: SignResponse) => sign.sign_status === 'New')
  }
  /* === Filter out all signs but 'Display Signs' ===*/
  if (signListView === DISPLAY_SIGN_VIEW) {
    return signList.filter(
      (sign: SignResponse) => sign.sign_type === 'Display Sign',
    )
  }
  /* === Filter out on pog signs for SPT view === */
  if (signListView === SPT_VIEW || signListView === SPT_SPLN_VIEW) {
    return signList.filter((sign: SignResponse) => sign.on_pog_sign === true)
  }
  /* === Filter out NOT on POG sign for VC view === */
  if (signListView === VC_VIEW) {
    return signList.filter((sign: SignResponse) => sign.on_pog_sign === false)
  }
  /* === All view except Standard & SPT & FINALIZATON should only show 'New' & 'Reissue' signs ===*/
  if (signListView !== STANDARD_VIEW && signListView !== FINALIZATION_VIEW) {
    return signList.filter(
      (sign: SignResponse) =>
        sign.sign_status === 'New' || sign.sign_status === 'Reissue',
    )
  }
  return signList
}

export const viewFilterRules = (view: AgGridSignListView) => {
  switch (view) {
    case STANDARD_VIEW:
      return 'Standard view shows all signs on this project.'
    case FINALIZATION_VIEW:
      return 'Finalization view shows all signs on this project.'
    case ESTIMATION_VIEW:
      return 'Estimation view shows all New and Reissue signs.'
    case SPT_VIEW:
    case SPT_SPLN_VIEW:
      return 'SPT view shows all on POG signs.'
    case VC_VIEW:
      return 'VC view shows all non-POG signs.'
    case PRINT_VIEW:
      return 'Print view shows all New and Reissue signs.'
    case IPP_DEMAND_VIEW:
      return 'IPP Demand view shows all New and Reissue signs.'
    case PRINT_VENDOR_DEMAND_VIEW:
      return 'Print Vendor Demand view shows all New and Reissue signs.'
    case SEPARATOR_AGENCY_VIEW:
      return 'Separator view shows all signs on this project.'
    case IRL_VIEW:
      return 'IRL view shows all New and Reissue signs.'
    case SPLN_VIEW:
      return 'SPLN view shows all New and Reissue signs.'
    case DISPLAY_SIGN_VIEW:
      return 'Display Sign view only shows Display sign types.'
    case CREATIVE_AGENCY_VIEW:
      return 'Agency Sign view only shows New signs for Creative Agencies.'
    default:
      return 'This view shows all New and Reissue signs in this project.'
  }
}

export const getSignStatusCount = (list: SignResponse[], status: string) => {
  return list.filter(
    (listItem: SignResponse) => listItem.sign_status === status,
  ).length
}

export const orderSignListByStatus = (signList: SignResponse[]) => {
  return orderBy(
    signList,
    [
      (sign: SignResponse) => sign.sign_status === 'Remove',
      (sign: SignResponse) => sign.sign_status === 'Carry Forward',
      (sign: SignResponse) => sign.sign_status === 'Reissue',
      (sign: SignResponse) => sign.sign_status === 'New',
    ],
    ['asc'],
  )
}

export const signNoteValueGetter = (params: ValueGetterParams) => {
  return get(params.data, `${params.column.getColId()}.content`, '')
}

export const signNoteValueSetter = (params: ValueSetterParams) => {
  const currentValue = get(params.data, `${params.column.getColId()}.content`)
  if (currentValue === undefined || currentValue !== params.newValue) {
    set(params.data, params.column.getColId(), {
      content: params.newValue,
      author: '',
      timestamp: new Date(),
    })
  }

  return params.newValue !== params.oldValue
}

export const endDateValueGetter = (params: ValueGetterParams) =>
  params.data?.non_retail_item_info.end_date
    ? moment(params.data.non_retail_item_info.end_date).format('MM/DD/YYYY')
    : ''

export const productInfoValueGetter = (params: ValueGetterParams) =>
  get(params.data, `messaging.item_info[0].${params.column.getColId()}`)

export const productInfoValueSetter = (params: ValueSetterParams) => {
  const currentValue = get(
    params.data,
    `messaging.item_info[0].${params.column.getColId()}`,
  )
  if (currentValue === undefined || params.newValue !== currentValue) {
    set(
      params.data,
      `messaging.item_info[0].${params.column.getColId()}`,
      params.newValue,
    )
  }
  return params.newValue !== params.oldValue
}

export const signSizeValueGetter = (params: ValueGetterParams) => {
  const signSizeValue = params.data?.messaging?.sign_size
  if (signSizeValue === undefined) {
    return undefined
  } else {
    return SIGN_SIZE_FACETS[params.data.messaging.sign_size]?.label
  }
}

export const totalQuantityValueGetter = (params: ValueGetterParams) => {
  const archwayQty = params.data?.distribution?.archway_quantity
  const nrscQty = params.data?.distribution?.nrsc_quantity

  if (archwayQty === undefined && nrscQty === undefined) {
    return undefined
  } else if (archwayQty === undefined && nrscQty !== undefined) {
    return parseInt(nrscQty)
  } else if (archwayQty !== undefined && nrscQty === undefined) {
    return parseInt(archwayQty)
  } else if (archwayQty !== undefined && nrscQty !== undefined) {
    return parseInt(archwayQty) + parseInt(nrscQty)
  } else {
    return undefined
  }
}

export const totalEstCost = (params: ValueGetterParams) => {
  if (
    params.data?.pricing?.estimated_quantity === undefined ||
    params.data?.pricing?.estimated_unit_price === undefined
  ) {
    return undefined
  } else {
    return (
      params.data?.pricing?.estimated_quantity *
      params.data?.pricing?.estimated_unit_price
    ).toFixed(2)
  }
}

export const totalFinalCost = (params: ValueGetterParams) => {
  const totalQty = totalQuantityValueGetter(params)
  return (
    params.data?.pricing?.final_unit_price &&
    totalQty &&
    (params.data?.pricing?.final_unit_price * totalQty).toFixed(2)
  )
}

export const dateComparator = (value1: string, value2: string) => {
  const _dateToNum = (date: string) => {
    if (date === undefined || date === null || date.length !== 10) {
      return null
    }
    return (
      parseInt(date.substring(6, 10)) * 10000 +
      parseInt(date.substring(0, 2)) * 100 +
      parseInt(date.substring(3, 5))
    )
  }
  const date1 = _dateToNum(value1)
  const date2 = _dateToNum(value2)
  return date1 === null && date2 === null
    ? 0
    : date1 === null
    ? -1
    : date2 === null
    ? 1
    : date1 - date2
}

export const dateFilterParams = {
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    if (cellValue === null) {
      return -1
    }
    const cellDate = new Date(cellValue)
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
  },
  browserDatePicker: true,
}

export const hasSameStatus = (signList: SignResponse[]) => {
  if (signList !== undefined) {
    const initStatus = signList[0].sign_status
    let isSame = true
    signList.map((sign) => {
      if (sign.sign_status !== initStatus) {
        return (isSame = false)
      }
      return null
    })
    return isSame
  }
  return false
}

export const isUpdatedWithinDay = (updatedDate: string) => {
  let currentDate = new Date().getTime()
  let difference = currentDate - new Date(updatedDate).getTime()
  return difference / 1000 / 60 / 60 < 24
}

export const validateNoExceptions = (
  modifiedSigns: TableEditedSign[],
  status: string,
) => {
  const exceptedFields = [
    'pricing',
    'distribution',
    'spt_sign_on_pog',
    'on_pog_sign',
  ]
  return modifiedSigns.some((sign: TableEditedSign) => {
    const keys = getObjectKeys(sign.fields)
    if (keys.length <= 4) {
      const keyChecks = keys.map((key: string) => {
        if (exceptedFields.includes(key)) {
          switch (key) {
            case 'pricing': {
              return false
            }
            case 'distribution': {
              if (POST_KIT_FINALIZATION.includes(status)) {
                return true
              } else {
                return false
              }
            }
            case 'spt_sign_on_pog': {
              if (POST_KIT_FINALIZATION.includes(status)) {
                return true
              } else {
                return false
              }
            }
            case 'on_pog_sign': {
              if (POST_KIT_FINALIZATION.includes(status)) {
                return true
              } else {
                return false
              }
            }
            default: {
              return true
            }
          }
        } else {
          return true
        }
      })
      return keyChecks.some((check: boolean) => check)
    }
    return true
  })
}

const getObjectKeys = (fields: TableEditorFields) => {
  return Object.keys(fields).filter(
    (key: string) => (fields as any)[key] !== undefined,
  )
}

export const rowClassRules = {
  'ag-row-highlight': (params: RowClassParams) =>
    isUpdatedWithinDay(params.data?.updated_date),
}
