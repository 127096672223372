import { concat } from 'lodash'

export const NRI_EXPORT_FIELDS = [
  { value: 'dpci', label: 'DPCI#', defaultSelected: true, sptField: true },
  {
    value: 'sign_name',
    label: 'Sign Name',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'sign_type',
    label: 'Sign Type',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'sign_template',
    label: 'Sign Template',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'sign_item_status',
    label: 'Sign Item Status',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'original_set_date',
    label: 'Original Set Date',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'sign_creator',
    label: 'Creation/Production Owner',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'end_date',
    label: 'End Date',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'pyramid',
    label: 'Pyramid',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'division',
    label: 'Division',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'department',
    label: 'Department',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'flat_width',
    label: 'Flat Width',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'flat_height',
    label: 'Flat Height',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'flat_depth',
    label: 'Flat Depth',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'finished_width',
    label: 'Finished Width',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'finished_height',
    label: 'Finished Height',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'finished_depth',
    label: 'Finished Depth',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'upc',
    label: 'Sign UPC',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'product_vendor',
    label: 'Product Vendor',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'separator',
    label: 'Separator',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'print_vendor',
    label: 'Print Vendor',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'created_date',
    label: 'Created Date',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'unit_of_measure',
    label: 'Unit of Measure',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'set_quantity',
    label: 'Set Quantity',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'planned_sides',
    label: 'Planned Sides',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'number_of_colors',
    label: 'Number of Colors',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'substrate1',
    label: 'Substrate 1',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'substrate2',
    label: 'Substrate 2',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'finishing',
    label: 'Finishing',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'coating',
    label: 'Coating',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'die_cut',
    label: 'Die Cut',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'on_press',
    label: 'On Press',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'pre_assembled',
    label: 'Pre-Assembled',
    defaultSelected: false,
    sptField: false,
  },
]

export const PROJECT_INFO_EXPORT_FIELDS = [
  {
    value: 'sap_project_id',
    label: 'SAP Project ID#',
    defaultSelected: true,
    sptField: false,
  },
  {
    value: 'project_name',
    label: 'SAP Project Name',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'blueprint_name',
    label: 'Blueprint Name',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'sign_status',
    label: 'Sign Status',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'project_set_date',
    label: 'Project Set Date',
    defaultSelected: true,
    sptField: true,
  },
  {
    value: 'campaign_id',
    label: 'Campaign ID',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'campaign_type',
    label: 'Campaign Type',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'workflow_status',
    label: 'Finalized Level',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_type',
    label: 'Project Type',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'on_pog_sign',
    label: 'On POG Sign',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'file_approved',
    label: 'File Approved',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_print_vendor',
    label: 'Project Print Vendor',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'kitting_vendor',
    label: 'Kitting Vendor',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'pog_categories',
    label: 'POG Categories',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'tie_to_product_dpci',
    label: 'Tie to Product DPCI',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'tie_to_hardware_dpci',
    label: 'Tie to Hardware DPCI',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'placement_on_shelf',
    label: 'SPT Placement on Shelf',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'quantity_per_placement',
    label: 'Quantity Per Placement',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'pog_unit_of_measure',
    label: 'POG Unit of Measure',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'pog_placement_notes',
    label: 'POG Placement Notes',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'pog_exclusions',
    label: 'POG Exclusions',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'spt_notes',
    label: 'SPT Notes',
    defaultSelected: false,
    sptField: true,
  },
  {
    value: 'estimated_quantity',
    label: 'Estimated Quantity',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'estimated_unit_price',
    label: 'Estimated Unit Price',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'nrsc_quantity',
    label: 'NRSC Quantity',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'archway_quantity',
    label: 'Archway Quantity',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'total_quantity',
    label: 'Total Quantity',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'final_unit_price',
    label: 'Final Unit Price',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_created_by',
    label: 'Project Created By',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_created_date',
    label: 'Project Created Date',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_updated_by',
    label: 'Project Updated By',
    defaultSelected: false,
    sptField: false,
  },
  {
    value: 'project_updated_date',
    label: 'Project Updated Date',
    defaultSelected: false,
    sptField: false,
  },
]

export const allNriFieldValues = NRI_EXPORT_FIELDS.map(
  (field: any) => field.value,
)
export const defaultNriFieldValues = NRI_EXPORT_FIELDS.filter(
  (field: any) => field.defaultSelected,
).map((field: any) => field.value)

export const sptNriFieldValues = NRI_EXPORT_FIELDS.filter(
  (field: any) => field.sptField,
).map((field: any) => field.value)

export const allProjectValues = PROJECT_INFO_EXPORT_FIELDS.map(
  (field: any) => field.value,
)
export const defaultProjectValues = PROJECT_INFO_EXPORT_FIELDS.filter(
  (field: any) => field.defaultSelected,
).map((field: any) => field.value)

export const sptProjectValues = PROJECT_INFO_EXPORT_FIELDS.filter(
  (field: any) => field.sptField,
).map((field: any) => field.value)

export const mergeSelectedFields = (
  nriFields: string[],
  projectFields: string[],
) =>
  Object.assign(
    {},
    ...concat(
      PROJECT_INFO_EXPORT_FIELDS.filter((field: any) =>
        projectFields.includes(field.value),
      ).map((field: any) => ({ [field.label]: field.value })),
      NRI_EXPORT_FIELDS.filter((field: any) =>
        nriFields.includes(field.value),
      ).map((field: any) => ({ [field.label]: field.value })),
    ),
  )
