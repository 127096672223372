import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { Button, Dropdown, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  CaretDownIcon,
  ChevronDownDoubleIcon,
  ChevronRightDoubleIcon,
  ExportIcon,
  ImagesIcon,
  ImportIcon,
  ListUnorderedIcon,
  MaximizeIcon,
  MinimizeIcon,
  PencilIcon,
  PlusIcon,
  ProcessingIcon,
  RedoIcon,
  TrashIcon,
  UndoIcon,
  WorkIcon,
} from '@enterprise-ui/icons'
import { useNavigate } from 'react-router'
import { useProjectDetailsContext } from '../../../../../context/projectDetailsContext'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import { useUserContext } from 'components/App/context/userContext'
import { useAppContext } from 'components/App/context/appContext'
import { useAgGridSignListContext } from '../../../context/agGridSignListContext'
import {
  PROJECT_READY_TO_KIT,
  UserType,
} from 'components/App/constants/appConstants'
import { DialogProps } from '../../../../../../../models/app/DialogProps.model'
import RunKitScenarioDropDownItem from '../../../../RunKitScenario/components/RunKitScenarioDropDownItem'
import { disableSignAction } from 'components/App/helpers/signEditHelper'
import { JobStatusNames } from '../../../../../../../models/job/JobStatus.model'
import { BehaviorSubject } from 'rxjs'
import { useSubscription } from 'observable-hooks'
import { LockColumnsButton } from 'components/common/LockColumnsButton'
import { LS_KEY } from '../AgGridSignListTable'
import { columnStateIsLocked } from 'utils/agGrid'

export const imagesFound$ = new BehaviorSubject(-1)

const ActionsDropdown = () => {
  const navigate = useNavigate()

  const { userInfo } = useUserContext()!
  const { projectSignListView, setDialogProps } = useAppContext()!
  const { currentProject, editReasonRequired } = useProjectDetailsContext()!
  const {
    projectSignList,
    finalizeAllSigns,
    setIsImportSignsFullScreen,
    isTableEditorFullScreen,
    setIsTableEditorFullScreen,
    setIsSignGroupOpen,
    allSignsFinalized,
    setIsBulkEditOpen,
    deleteSigns,
    setShowReasonCodeModal,
    setActionType,
    setSignIds,
    signImportResponse,
    refreshPogs,
    setMoveModalOpen,
    setShowExportPdfModal,
  } = useProjectSignListContext()!
  const {
    gridApi,
    selectedRows,
    setSelectedRows,
    sizeColumnsToFit,
    autoSizeAllColumns,
    resetColumnState,
    resetAllFilters,
    expandAllGroupRows,
    collapseAllGroupRows,
  } = useAgGridSignListContext()!

  const [imagesFound, setImagesFound] = useState(-1)

  useEffect(() => {
    if (!selectedRows.length) imagesFound$.next(0)
  }, [selectedRows])

  useSubscription(imagesFound$, setImagesFound)

  const isDisabledAction = useCallback(
    (action: string) => {
      return projectSignList.length > 0 && action === 'finalizeAllSigns'
        ? disableSignAction(action, currentProject, projectSignList, userInfo)
        : disableSignAction(action, currentProject, selectedRows, userInfo)
    },
    [currentProject, projectSignList, selectedRows, userInfo],
  )

  const importRunning =
    signImportResponse.status.name === JobStatusNames.RUNNING
  const userCanViewAction =
    userInfo.permissions.isSignEditor ||
    userInfo.permissions.isSignAdmin ||
    userInfo.permissions.isKitAdmin
  const userIsAdmin =
    userInfo.permissions.isSignAdmin || userInfo.permissions.isKitAdmin
  const userCanRunKits = userInfo.permissions.isKitEditor
  const readyForKitting = PROJECT_READY_TO_KIT.includes(
    currentProject.workflow.status,
  )

  return (
    <Fragment>
      <Grid.Item className="hc-ph-dense hc-pb-dense">
        <Dropdown size="expanded" location="bottom-left">
          <Button type="ghost">
            Actions
            <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              disabled={!columnStateIsLocked(LS_KEY)}
            >
              <LockColumnsButton lsKey={LS_KEY} />
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() =>
                setIsTableEditorFullScreen(!isTableEditorFullScreen)
              }
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={isTableEditorFullScreen ? MinimizeIcon : MaximizeIcon}
              />
              {isTableEditorFullScreen
                ? 'Close Full Screen View'
                : 'Open Full Screen View'}
            </Dropdown.MenuItem>
            {userCanViewAction && userInfo.type === UserType.INTERNAL ? (
              <Dropdown.MenuItem
                className={`${
                  projectSignListView.viewKey === 'ipp' &&
                  'sem_DropdownMenuItem--action divider'
                }`}
                disabled={isDisabledAction('import') || importRunning}
                onClick={() => {
                  if (editReasonRequired) {
                    setActionType('Import')
                    setShowReasonCodeModal(true)
                  } else {
                    setIsImportSignsFullScreen(true)
                  }
                }}
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={ImportIcon}
                />
                Import Signs
              </Dropdown.MenuItem>
            ) : null}
            {userCanViewAction && userInfo.type === UserType.INTERNAL && (
              <Dropdown.MenuItem
                className={`${
                  projectSignListView.viewKey === 'ipp' &&
                  'sem_DropdownMenuItem--action divider'
                }`}
                disabled={!selectedRows.length || imagesFound < 1}
                onClick={() => {
                  setShowExportPdfModal(true)
                }}
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={ImagesIcon}
                />
                Export Images{' '}
                {imagesFound === -1 ? <Spinner /> : `(${imagesFound} found)`}
              </Dropdown.MenuItem>
            )}
            {[
              'Kits Finalized',
              'Kits Modified (Finalization)',
              'Recreate Kits (Finalization)',
            ].includes(currentProject.workflow.status) &&
            (userInfo.permissions.isKitEditor ||
              userInfo.permissions.isKitAdmin) ? (
              <Dropdown.MenuItem onClick={refreshPogs}>
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={ProcessingIcon}
                />
                Refresh POGs
              </Dropdown.MenuItem>
            ) : null}
            {userCanViewAction ? (
              <Dropdown.MenuItem
                className="sem_DropdownMenuItem--action divider"
                disabled={isDisabledAction('addNew')}
                onClick={() =>
                  navigate(
                    `/projects/${currentProject.project_id}/signs/create`,
                  )
                }
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={PlusIcon}
                />
                Add New Sign
              </Dropdown.MenuItem>
            ) : null}
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              onClick={() =>
                gridApi?.exportDataAsCsv({
                  columnKeys: projectSignListView.exportColumns,
                  fileName: `${currentProject.sap_project_id} ${currentProject.name}.csv`,
                })
              }
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={ExportIcon}
              />
              Export as CSV
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              disabled={isDisabledAction('groupSigns')}
              onClick={() => setIsSignGroupOpen(true)}
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={ListUnorderedIcon}
              />
              Group Selected Signs
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={() => expandAllGroupRows()}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={ChevronDownDoubleIcon}
              />
              Expand All Groups Rows
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              onClick={() => collapseAllGroupRows()}
            >
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={ChevronRightDoubleIcon}
              />
              Collapse All Groups Rows
            </Dropdown.MenuItem>
            {userCanViewAction || userInfo.permissions.isKitEditor ? (
              <Dropdown.MenuItem
                className={`sem_DropdownMenuItem--action ${
                  userCanViewAction || userInfo.permissions.isKitEditor
                    ? ''
                    : 'divider'
                }`}
                disabled={isDisabledAction('bulkEditSelected')}
                onClick={() => {
                  setIsBulkEditOpen(true)
                }}
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={PencilIcon}
                />
                Bulk Edit Selected Signs
              </Dropdown.MenuItem>
            ) : null}
            {userCanViewAction ? (
              <Dropdown.MenuItem
                className={`sem_DropdownMenuItem--action ${
                  userCanViewAction && !userIsAdmin ? 'divider' : ''
                }`}
                disabled={isDisabledAction('deleteSigns')}
                onClick={() => {
                  if (editReasonRequired) {
                    setShowReasonCodeModal(true)
                    setActionType('DeleteSigns')
                    setSignIds(selectedRows.map((sign) => sign.sign_id))
                  } else {
                    setDialogProps(
                      new DialogProps({
                        headingText: `Delete ${
                          selectedRows.length > 1
                            ? `${selectedRows.length} selected signs`
                            : '1 selected sign'
                        } from this project?`,
                        approveButtonText: 'Delete',
                        refuseButtonText: 'Cancel',
                        onApprove: () => {
                          deleteSigns(
                            selectedRows.map((sign) => sign.sign_id)!,
                            undefined,
                          )
                          setDialogProps(new DialogProps())
                        },
                        onRefuse: () => {
                          setDialogProps(new DialogProps())
                        },
                      }),
                    )
                  }
                  setSelectedRows([])
                }}
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={TrashIcon}
                />
                Delete Selected Signs from Project
              </Dropdown.MenuItem>
            ) : null}
            {userIsAdmin ? (
              <Dropdown.MenuItem
                className="sem_DropdownMenuItem--action divider"
                disabled={isDisabledAction('moveSelectedSigns')}
                onClick={() => setMoveModalOpen(true)}
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={ExportIcon}
                />
                Move Selected Signs To Another Project
              </Dropdown.MenuItem>
            ) : null}
            {userIsAdmin ? (
              <Dropdown.MenuItem
                className={`sem_DropdownMenuItem--action ${
                  userIsAdmin && !userCanRunKits ? 'divider' : ''
                }`}
                disabled={isDisabledAction('finalizeAllSigns')}
                onClick={() =>
                  setDialogProps(
                    new DialogProps({
                      headingText:
                        'Are you sure you want to finalize all signs?',
                      approveButtonText: 'Yes',
                      onApprove: () => {
                        setDialogProps(new DialogProps())
                        finalizeAllSigns(
                          currentProject.project_id,
                          userInfo.name,
                        )
                      },
                      refuseButtonText: 'Cancel',
                      onRefuse: () => setDialogProps(new DialogProps()),
                    }),
                  )
                }
              >
                <EnterpriseIcon
                  size="inline"
                  className="hc-mr-normal"
                  icon={WorkIcon}
                />
                {allSignsFinalized
                  ? 'All Signs Finalized'
                  : 'Finalize All Signs'}
              </Dropdown.MenuItem>
            ) : null}
            {userCanRunKits ? (
              <RunKitScenarioDropDownItem disabled={!readyForKitting} />
            ) : null}
            {/* === Ag-Grid action items === */}
            <Dropdown.MenuItem onClick={() => resetAllFilters()}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={RedoIcon}
              />
              Reset All Column Filters
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={sizeColumnsToFit}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={MinimizeIcon}
              />
              Size Columns to Fit
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={autoSizeAllColumns}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={MaximizeIcon}
              />
              Auto Size All Columns
            </Dropdown.MenuItem>
            <Dropdown.MenuItem onClick={resetColumnState}>
              <EnterpriseIcon
                size="inline"
                className="hc-mr-normal"
                icon={UndoIcon}
              />
              Reset Column Sizes
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Item>
    </Fragment>
  )
}

export default ActionsDropdown
