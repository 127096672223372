import React from 'react'
import { Button, Dropdown, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useExportOptionsContext } from '../../../context/exportOptionsContext'
//import { useAgGridSignLibraryContext } from '../../../context/agGridSignLibraryContext'
import {
  allNriFieldValues,
  allProjectValues,
  defaultNriFieldValues,
  defaultProjectValues,
} from '../../../helpers/exportOptionHelpers'
import EnterpriseIcon, {
  CancelCircleIcon,
  CancelIcon,
  CaretDownIcon,
  CheckIcon,
  ChecklistIcon,
  DownloadIcon,
} from '@enterprise-ui/icons'

const ExportOptionsButtonRow = () => {
  //const { totalResults } = useAgGridSignLibraryContext()!
  const {
    setExportOpen,
    setNriSelectedFields,
    allNriSelected,
    setAllNriSelected,
    defaultNriSelected,
    setDefaultNriSelected,
    setProjectSignSelectedFields,
    allProjectSelected,
    setAllProjectSelected,
    defaultProjectSelected,
    setDefaultProjectSelected,
    exportRecords,
  } = useExportOptionsContext()!

  const handleSelectAll = (selectAll: boolean, type: string) => {
    if (type === 'nri') {
      setAllNriSelected(selectAll)
      setNriSelectedFields(selectAll ? allNriFieldValues : [])
      setDefaultNriSelected(false)
    } else if (type === 'project') {
      setAllProjectSelected(selectAll)
      setProjectSignSelectedFields(selectAll ? allProjectValues : [])
      setDefaultProjectSelected(false)
    }
  }

  const handleSelectDefault = (selectDefault: boolean, type: string) => {
    if (type === 'nri') {
      setDefaultNriSelected(selectDefault)
      setNriSelectedFields(selectDefault ? defaultNriFieldValues : [])
      setAllNriSelected(false)
    } else if (type === 'project') {
      setDefaultProjectSelected(selectDefault)
      setProjectSignSelectedFields(selectDefault ? defaultProjectValues : [])
      setAllProjectSelected(false)
    }
  }

  const handleClose = () => setExportOpen(false)

  return (
    <Grid.Container align="center" spacing="dense" className="hc-mb-dense">
      <Grid.Item className="hc-ph-dense" xs={true}>
        <Dropdown size="normal" location="bottom-left">
          <Button type="ghost">
            Export Options
            <EnterpriseIcon className="hc-ml-dense" icon={CaretDownIcon} />
          </Button>
          <Dropdown.Menu>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() => exportRecords()}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={DownloadIcon} />
              Export Selected Fields
              {/*<span className="hc-ml-dense sem_StrongText">{`(${*/}
              {/*  totalResults === undefined ? (*/}
              {/*    <Spinner size="inline" />*/}
              {/*  ) : (*/}
              {/*    totalResults.toLocaleString('en-US')*/}
              {/*  )*/}
              {/*} Records)`}</span>*/}
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              onClick={() => handleClose()}
            >
              <EnterpriseIcon
                className="hc-mr-normal"
                icon={CancelCircleIcon}
              />
              Cancel Export
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() => handleSelectAll(!allProjectSelected, 'project')}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={CheckIcon} />
              {allProjectSelected ? 'Deselect' : 'Select'} All Project Sign
              Fields
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() =>
                handleSelectDefault(!defaultProjectSelected, 'project')
              }
            >
              <EnterpriseIcon className="hc-mr-normal" icon={ChecklistIcon} />
              {defaultProjectSelected ? 'Deselect' : 'Select'} Default Project
              Sign Fields
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action"
              onClick={() => handleSelectAll(!allNriSelected, 'nri')}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={CheckIcon} />
              {allNriSelected ? 'Deselect' : 'Select'} All Non-Retail Item
              Fields
            </Dropdown.MenuItem>
            <Dropdown.MenuItem
              className="sem_DropdownMenuItem--action divider"
              onClick={() => handleSelectDefault(!defaultNriSelected, 'nri')}
            >
              <EnterpriseIcon className="hc-mr-normal" icon={ChecklistIcon} />
              {defaultNriSelected ? 'Deselect' : 'Select'} Default Non-Retail
              Item Fields
            </Dropdown.MenuItem>
          </Dropdown.Menu>
        </Dropdown>
      </Grid.Item>
      <Grid.Item>
        <Button
          type="ghost"
          className="sem_IconBtn"
          iconOnly
          size="dense"
          onClick={() => handleClose()}
        >
          <EnterpriseIcon icon={CancelIcon} />
        </Button>
      </Grid.Item>
    </Grid.Container>
  )
}

export default ExportOptionsButtonRow
