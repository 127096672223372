import { useFormik } from 'formik'
import SignRequest from '../../../models/signs/SignRequest.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import { RosterElement } from '../../../models/roster/RosterElement.model'
import { NEW_SIGN } from '../constants/signDetailsConstants'

export const useSignInfoForm = (
  currentSign: SignResponse,
  handleSubmit: (req: SignRequest) => void,
  userCompany: string,
  projectRoster: RosterElement[],
) => {
  const validate = (values: SignResponse) => {
    const errors: any = {}
    if (currentSign.sign_id === NEW_SIGN) {
      if (values.sign_type === '') {
        errors.sign_type =
          'Selecting a Sign Type is required to create a new sign.'
      }
      if (values.sign_template_info.template_id === '') {
        errors.sign_template_info = {
          template_id: 'Selecting a Template is required to create a new sign.',
        }
      }
      if (values.description === '') {
        errors.description =
          'A Sign Description is required to create a new sign.'
      }
    } else {
      if (values.sign_type === '') {
        errors.sign_type = 'Selecting a Sign Type is required to save.'
      }
      if (values.description === '') {
        errors.description = 'A Sign Description is required to save.'
      }
    }

    return errors
  }

  return useFormik({
    enableReinitialize: true,
    initialValues: new SignResponse(currentSign),
    validate,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit(values) {
      handleSubmit(
        new SignRequest({
          ...values,
          sign_template_id: values.sign_template_info.template_id,
          product_vendor: values.non_retail_item_info.product_vendor,
          item_dimensions:
            values.sign_template_info.template_name.toLowerCase() ===
              'custom' ||
            values.sign_template_info.template_name.toLowerCase() === 'na'
              ? values.non_retail_item_info.item_dimensions
              : undefined,
          nominal_dimensions: values.non_retail_item_info.nominal_dimensions,
          end_date: values.non_retail_item_info.end_date,
          project_id: currentSign.project_id,
          updated_by: currentSign.updated_by,
        }),
      )
    },
  })
}
