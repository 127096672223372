import React, { useCallback, useEffect } from 'react'
import { AgGridColumn, AgGridReact } from 'ag-grid-react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useAppContext } from '../../../App/context/appContext'
import { useAgGridSignImportContext } from '../../context/agGridSignImportContext'
import { Column } from '../../../../models/agGrid/AgGridColumn.model'
import { SIGN_IMPORT_VIEW } from '../../views/AgGridSignImportViews'
import { SIGN_LIBRARY_STANDARD_VIEW } from '../../../SignLibrary/views/AgGridSignLibraryView'
import { useProjectSignListContext } from '../../../ProjectDetails/components/ProjectSignList/context/projectSignListContext'

const AgGridSignImportTable = () => {
  const { signLibraryView, setSignLibraryView } = useAppContext()!
  const { signListLoaded, isImportSignsFullScreen } =
    useProjectSignListContext()!
  const { readyToLoadGrid, setReadyToLoadGrid, gridOptions, onGridReady } =
    useAgGridSignImportContext()!

  const loadGridStatus = useCallback(() => {
    signListLoaded && isImportSignsFullScreen && setReadyToLoadGrid(true)
  }, [isImportSignsFullScreen, setReadyToLoadGrid, signListLoaded])

  useEffect(() => {
    loadGridStatus()
    setSignLibraryView(SIGN_IMPORT_VIEW)

    return () => setSignLibraryView(SIGN_LIBRARY_STANDARD_VIEW)
  }, [loadGridStatus, setSignLibraryView])

  return (
    <Grid.Item className="hc-pt-none" xs={12}>
      {readyToLoadGrid ? (
        <div className="ag-theme-rubix sign-import-table">
          <AgGridReact
            gridOptions={gridOptions}
            onGridReady={onGridReady}
            rowHeight={48}
            rowModelType="serverSide"
            serverSideStoreType="partial"
            suppressRowClickSelection
          >
            {signLibraryView.columns.map((column: Column, index: number) => (
              <AgGridColumn
                headerName={column.headerName}
                field={column.field}
                width={column.width || 148}
                type={column.type}
                filter={column.filter || 'agTextColumnFilter'}
                colId={column.colId}
                key={index}
              />
            ))}
          </AgGridReact>
        </div>
      ) : null}
    </Grid.Item>
  )
}

export default AgGridSignImportTable
