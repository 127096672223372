export default class FilterFacets {
  [index: string]: any
  sign_type: string[]
  sign_item_status: string[]
  sign_status: string[]
  product_vendor: string[]
  separator: string[]
  pyramid: string[]
  division: string[]
  department: string[]
  project_type: string[]
  campaign: string[]
  printing_vendor: string[]
  kitting_vendor: string[]
  sign_template: string[]

  constructor(props: any = {}) {
    this.sign_type = props.sign_type || []
    this.sign_item_status = props.sign_item_status || []
    this.sign_status = props.sign_status || []
    this.product_vendor = props.product_vendor || []
    this.separator = props.separator || []
    this.pyramid = props.pyramid || []
    this.division = props.division || []
    this.department = props.department || []
    this.project_type = props.project_type || []
    this.campaign = props.campaign || []
    this.printing_vendor = props.printing_vendor || []
    this.kitting_vendor = props.kitting_vendor || []
    this.sign_template = props.sign_template
  }
}
