import { Department } from '../merchandise/hierarchy/Department.model'
import { Dimensions, Workflow } from './SignResponse.model'

export default class SignRequest {
  project_id?: string
  sign_id?: string
  sign_type: string
  sign_template_id: string
  sign_status?: string
  sign_item_status?: string
  sign_creator?: string
  sign_language?: string
  description?: string
  brand?: string
  non_retail_item_tcin?: string
  product_vendor?: string
  end_date?: string
  department: Department
  item_dimensions?: Dimensions
  nominal_dimensions: Dimensions
  sales_plan?: boolean
  photography_needed?: boolean
  on_pog_sign: boolean
  workflow: Workflow
  unit_of_measure: string
  set_quantity?: number
  separator?: string
  priority?: string
  endcap_type?: string
  program_name?: string
  ism_strategy?: string
  third_party_shipping?: boolean
  spt_sign_on_pog?: boolean
  reason_code?: string
  group?: string
  pog_categories?: any[]
  tie_to_product_dpci?: string
  tie_to_hardware_dpci?: string
  placement_on_shelf?: string
  quantity_per_placement?: string
  pog_unit_of_measure?: string
  pog_exclusions?: string
  updated_by: string

  constructor(props: any = {}) {
    this.project_id = props.project_id
    this.sign_id = props.sign_id
    this.sign_type = props.sign_type || ''
    this.sign_template_id = props.sign_template_id
    this.sign_status = props.sign_status || 'New'
    this.sign_item_status = props.sign_item_status
    this.sign_language = props.sign_language
    this.sign_creator = props.sign_creator
    this.description = props.description
    this.brand = props.brand
    this.non_retail_item_tcin = props.non_retail_item_tcin
    this.product_vendor = props.product_vendor
    this.end_date = props.end_date
    this.department = new Department(props.department || {})
    this.item_dimensions = new Dimensions(props.item_dimensions || {})
    this.nominal_dimensions = new Dimensions(props.nominal_dimensions || {})
    this.sales_plan = props.sales_plan || false
    this.photography_needed = props.photography_needed || false
    this.on_pog_sign = props.on_pog_sign
    this.workflow = new Workflow(props.workflow || {})
    this.unit_of_measure = props.unit_of_measure || ''
    this.set_quantity = props.set_quantity
    this.separator = props.separator || ''
    this.priority = props.priority
    this.endcap_type = props.endcap_type
    this.program_name = props.program_name
    this.ism_strategy = props.ism_strategy
    this.third_party_shipping = props.third_party_shipping
    this.spt_sign_on_pog = props.spt_sign_on_pog
    this.reason_code = props.reason_code || null
    this.group = props.group
    this.pog_categories = props.pog_categories || null
    this.tie_to_product_dpci = props.tie_to_product_dpci || null
    this.tie_to_hardware_dpci = props.tie_to_hardware_dpci || null
    this.placement_on_shelf = props.placement_on_shelf || null
    this.quantity_per_placement = props.quantity_per_placement || null
    this.pog_unit_of_measure = props.pog_unit_of_measure || null
    this.pog_exclusions = props.pog_exclusions || null
    this.updated_by = props.updated_by || ''
  }
}
