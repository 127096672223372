import React from 'react'
import { Input, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import { useExportOptionsContext } from '../../../context/exportOptionsContext'
import {
  allNriFieldValues,
  defaultNriFieldValues,
  NRI_EXPORT_FIELDS,
  sptNriFieldValues,
} from '../../../helpers/exportOptionHelpers'
import { isEqual } from 'lodash'

const NonRetailFields = () => {
  const {
    nriSelectedFields,
    setNriSelectedFields,
    allNriSelected,
    setAllNriSelected,
    defaultNriSelected,
    setDefaultNriSelected,
    sptNriSelected,
    setSptNriSelected,
  } = useExportOptionsContext()!

  const handleSelectAll = (selectAll: boolean) => {
    setAllNriSelected(selectAll)
    setNriSelectedFields(selectAll ? allNriFieldValues : [])
    setDefaultNriSelected(false)
    setSptNriSelected(false)
  }

  const handleSelectDefault = (selectDefault: boolean) => {
    setDefaultNriSelected(selectDefault)
    setNriSelectedFields(selectDefault ? defaultNriFieldValues : [])
    setAllNriSelected(false)
    setSptNriSelected(false)
  }

  const handleSelectSpt = (selectSpt: boolean) => {
    setSptNriSelected(selectSpt)
    setNriSelectedFields(selectSpt ? sptNriFieldValues : [])
    setAllNriSelected(false)
    setDefaultNriSelected(false)
  }

  const handleSelectField = (selectedFields: string[]) => {
    setNriSelectedFields(selectedFields)
    setAllNriSelected(isEqual(selectedFields.sort(), allNriFieldValues.sort()))
    setDefaultNriSelected(
      isEqual(selectedFields.sort(), defaultNriFieldValues.sort()),
    )
    setSptNriSelected(isEqual(selectedFields.sort(), sptNriFieldValues.sort()))
  }

  return (
    <Grid.Container spacing="none">
      <Grid.Item xs={12}>
        <Heading size={5} className="hc-clr-grey01 hc-pv-normal">
          Non-Retail Item Fields
        </Heading>
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectAllNri"
          checked={allNriSelected}
          label="All Non-Retail Fields"
          onUpdate={(id: string, value: boolean) => handleSelectAll(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectDefaultNri"
          checked={defaultNriSelected}
          label="Default Non-Retail Fields"
          onUpdate={(id: string, value: boolean) => handleSelectDefault(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12}>
        <Input.Checkbox
          id="selectSpt"
          checked={sptNriSelected}
          label="SPT Non-Retail Fields"
          onUpdate={(id: string, value: boolean) => handleSelectSpt(value)}
        />
      </Grid.Item>
      <Grid.Item xs={12} className="hc-ml-expanded">
        <Input.Checkboxes
          id="selectNriFields"
          value={nriSelectedFields}
          onUpdate={(id: string, value: any) => handleSelectField(value)}
          options={NRI_EXPORT_FIELDS}
        />
      </Grid.Item>
    </Grid.Container>
  )
}

export default NonRetailFields
