import React, { createContext, useState, useContext, useCallback } from 'react'
import { connect } from 'react-redux'
import { set } from 'lodash'
import {
  CellClassParams,
  CellValueChangedEvent,
  ColumnApi,
  EditableCallbackParams,
  FillOperationParams,
  FilterChangedEvent,
  GridApi,
  GridOptions,
  GridReadyEvent,
  RowNode,
  SelectionChangedEvent,
  SortChangedEvent,
  ValueGetterParams,
  ValueSetterParams,
} from 'ag-grid-community'
import FinalizeLevelCell from '../components/signListViews/components/FinalizeLevelCell'
import TableChip from '../components/signListViews/components/TableChip'
import TableToggleComponent from '../components/signListViews/components/TableToggleComponent'
import TableDropdownMenu from '../components/signListViews/components/TableDropdownMenu'
import SignDetailLink from '../components/signListViews/components/TableSignDetailLink'
import TableDateComponent from '../components/signListViews/components/TableDateComponent'
import TableNotesComponent from '../components/signListViews/components/TableNotesComponent'
import NumberCellEditor from '../components/signListViews/components/NumberCellEditor'
import AutocompleteCellEditor from '../components/signListViews/components/AutocompleteCellEditor'
import DateCellEditor from '../components/signListViews/components/DateCellEditor'
import LoadingSigns from '../components/signListViews/components/LoadingSigns'
import ToggleCellEditor from '../components/signListViews/components/ToggleCellEditor'
import TextCellEditor from '../components/signListViews/components/TextCellEditor'
import SelectCellEditor from '../components/signListViews/components/SelectCellEditor'
import DepartmentCellEditor from '../components/signListViews/components/DepartmentCellEditor'
import DepartmentComponent from '../components/signListViews/components/DepartmentComponent'
import QuantityCell from '../components/signListViews/components/QuantityCell'
import POGCell from '../components/signListViews/components/POGCell'
import NotesCellEditor from '../components/signListViews/components/NotesCellEditor'
import KitGroupCell from '../components/signListViews/components/KitGroupCell'
import POGEditorCell from '../components/signListViews/components/POGEditorCell'
import ProductInfoCell from '../components/signListViews/components/ProductInfoCell'
import ProductInfoEditor from '../components/signListViews/components/ProductInfoEditor'
import SetQuantityCell from '../components/signListViews/components/SetQuantityCell'
import PogCategoriesEditor from '../components/signListViews/components/PogCategoriesEditor'
import { useAppContext } from 'components/App/context/appContext'
import { useUserContext } from 'components/App/context/userContext'
import AutocompletesubstrateCellEditor from '../components/signListViews/components/AutocompletesubstrateCellEditor'
import { useProjectDetailsContext } from '../../../context/projectDetailsContext'
import { useProjectSignListContext } from './projectSignListContext'
import SignResponse from '../../../../../models/signs/SignResponse.model'
import { SortState } from '../../../../../models/agGrid/AgGridSortState.model'
import { TableEditedSign } from '../../../../../models/signs/TableEditorRequest.model'
import TableEditorFields from '../../../../../models/signs/TableEditorFields.model'
import { fieldIsEditable } from 'components/App/helpers/signEditHelper'
import {
  endDateValueGetter,
  dateFilterParams,
  dateComparator,
  productInfoValueGetter,
  productInfoValueSetter,
  totalQuantityValueGetter,
  totalEstCost,
  totalFinalCost,
  signNoteValueGetter,
  signNoteValueSetter,
  signSizeValueGetter,
} from '../helpers/signListHelper'
import SignStatusEditor from '../components/signListViews/components/SignStatusEditor'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'
import { DialogProps } from '../../../../../models/app/DialogProps.model'
import { SIGN_SIZE_FACETS } from 'components/SignDetails/constants/signMessagingFacets'
import { useToaster } from '@enterprise-ui/canvas-ui-react'
import { TOASTER_DEFAULTS } from 'components/App/constants/appConstants'
import PogCategoriesCell from 'components/ProjectDetails/components/ProjectSignList/components/signListViews/components/PogCategoriesCell'
import { DropdownOption } from '../../../../../models/app/DropdownOption.model'

type ContextType = {
  gridApi: GridApi | undefined
  setGridApi: Function
  gridColumnApi: ColumnApi | undefined
  setGridColumnApi: Function
  onGridReady: (event: GridReadyEvent) => void
  rowData: SignResponse[]
  setRowData: Function
  gridOptions: GridOptions
  onFirstDataRender: () => void
  onSelectionChange: (event: SelectionChangedEvent) => void
  selectedRows: SignResponse[]
  setSelectedRows: Function
  onCellValueChange: (event: CellValueChangedEvent) => void
  updateSignGroup: (sign: SignResponse) => void
  onRowDragEnd: (event: any) => void
  handleAddSignToGroup: (group: string) => void
  onFilterChange: (event: FilterChangedEvent) => void
  onSortChanged: (event: SortChangedEvent) => void
  sizeColumnsToFit: () => void
  autoSizeAllColumns: () => void
  resetColumnState: () => void
  resetAllFilters: () => void
  expandAllGroupRows: () => void
  collapseAllGroupRows: () => void
  fillOperation: (params: FillOperationParams) => void
}

export const AgGridSignListContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const AgGridSignListProviderComponent = ({ children }: Props) => {
  const { userName, userInfo } = useUserContext()!
  const {
    projectSignListSavedFilter,
    setProjectSignListSavedFilter,
    projectSignListSortState,
    setProjectSignListSortState,
    setDialogProps,
    pogCategoryOptions,
  } = useAppContext()!
  const {
    projectSignList,
    setSelectedStatus,
    setSignsModified,
    modifiedSignIds,
    setModifiedSignIds,
    modifiedSigns,
    setModifiedSigns,
    signTemplateOptions,
  } = useProjectSignListContext()!
  const { currentProject } = useProjectDetailsContext()!

  const makeToast = useToaster()

  const [gridApi, setGridApi] = useState<GridApi>()
  const [gridColumnApi, setGridColumnApi] = useState<ColumnApi>()
  const [rowData, setRowData] = useState<SignResponse[]>(projectSignList)
  const [selectedRows, setSelectedRows] = useState<SignResponse[]>([])

  const isEditableField = useCallback(
    (params: EditableCallbackParams | CellClassParams) =>
      fieldIsEditable(
        params.data,
        params.colDef.field!,
        userInfo,
        currentProject,
      ),
    [currentProject, userInfo],
  )

  const onGridReady = (event: GridReadyEvent) => {
    setGridApi(event.api)
    setGridColumnApi(event.columnApi)
  }

  const gridOptions = {
    animateRows: true,
    defaultColDef: {
      resizable: true,
      sortable: true,
      width: 148,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        buttons: ['clear', 'reset'],
        newRowsAction: 'keep',
      },
    },
    autoGroupColumnDef: {
      lockPinned: true,
      pinned: 'left',
    },
    columnTypes: {
      fixed: {
        lockPinned: true,
        resizable: false,
        suppressSizeToFit: true,
        pinned: 'left',
      },
      selectedRow: {
        width: 70,
        maxWidth: 70,
        filter: false,
        sortable: false,
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        rowDrag: true,
      },
      signMenu: {
        width: 36,
        maxWidth: 36,
        filter: false,
        sortable: false,
        cellRenderer: 'TableDropdownMenu',
        cellClass: 'actions-button-cell dropdown-menu',
      },
      finalizeCell: {
        cellRenderer: 'FinalizeLevelCell',
        cellClass: 'actions-button-cell',
      },
      signStatusEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'SignStatusEditor',
        cellEditorPopup: true,
      },
      quantityCell: {
        cellRenderer: 'QuantityCell',
        cellClass: 'actions-button-cell',
      },
      setQuantity: {
        cellRenderer: 'SetQuantityCell',
      },
      totalQty: {
        valueGetter: (params: ValueGetterParams) =>
          totalQuantityValueGetter(params),
      },
      totalEstCost: {
        valueGetter: (params: ValueGetterParams) => totalEstCost(params),
      },
      totalFinalCost: {
        valueGetter: (params: ValueGetterParams) => totalFinalCost(params),
      },
      signSize: {
        valueGetter: (params: ValueGetterParams) => signSizeValueGetter(params),
      },
      tableChip: {
        cellRenderer: 'TableChip',
      },
      toggle: {
        cellRenderer: 'TableToggle',
      },
      pogCell: {
        cellRenderer: 'POGCell',
        cellClass: 'actions-button-cell',
      },
      signDetailLink: {
        cellRenderer: 'SignDetailLink',
      },
      pogCategoriesCell: {
        cellRenderer: 'PogCategoriesCell',
      },
      date: {
        cellRenderer: 'TableDate',
        valueGetter: (params: ValueGetterParams) => endDateValueGetter(params),
        filterParams: dateFilterParams,
        comparator: dateComparator,
      },
      notes: {
        sortable: false,
        cellRenderer: 'TableNote',
        cellClass: 'actions-button-cell',
        valueGetter: (params: ValueGetterParams) => signNoteValueGetter(params),
      },
      kitGroups: {
        cellRenderer: 'KitGroupCell',
        cellClass: 'actions-button-cell',
      },
      productInfo: {
        valueGetter: (params: ValueGetterParams) =>
          productInfoValueGetter(params),
      },
      hiddenColumn: {
        hide: true,
      },
      textEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'TextCellEditor',
        cellEditorPopup: true,
      },
      numberEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'NumberCellEditor',
        cellEditorPopup: true,
      },
      selectEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'SelectCellEditor',
        cellEditorPopup: true,
      },
      departmentEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellRenderer: 'DepartmentRenderer',
        cellEditor: 'DepartmentCellEditor',
        cellEditorPopup: true,
      },
      dateEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'DateCellEditor',
        cellEditorPopup: true,
      },
      toggleEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'ToggleCellEditor',
        cellEditorPopup: true,
        cellClassRules: {
          'not-editable': (params: CellClassParams) =>
            isEditableField(params) !== true,
        },
      },
      pogEditorCell: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'POGEditor',
        cellEditorPopup: true,
        cellClassRules: {
          'not-editable': (params: CellClassParams) =>
            isEditableField(params) !== true,
        },
        cellClass: 'actions-button-cell pog-editor',
      },
      autocompleteEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'AutocompleteCellEditor',
        cellEditorPopup: true,
        cellClass: 'actions-button-cell',
      },
      autocompletesubstrateEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'AutocompletesubstrateCellEditor',
        cellEditorPopup: true,
        cellClass: 'actions-button-cell',
      },
      pogCategoriesEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'PogCategoriesEditor',
        cellEditorPopup: true,
        cellClass: 'actions-button-cell',
      },
      productInfoEditor: {
        editable: (params: EditableCallbackParams) => isEditableField(params),
        cellEditor: 'ProductInfoEditor',
        cellEditorPopup: true,
        valueGetter: (params: ValueGetterParams) =>
          productInfoValueGetter(params),
        valueSetter: (params: ValueSetterParams) =>
          productInfoValueSetter(params),
      },
      notesEditor: {
        editable: true,
        cellEditor: 'NotesEditor',
        cellEditorPopup: true,
        valueGetter: (params: ValueGetterParams) => signNoteValueGetter(params),
        valueSetter: (params: ValueSetterParams) => signNoteValueSetter(params),
      },
    },
    frameworkComponents: {
      FinalizeLevelCell: FinalizeLevelCell,
      TableChip: TableChip,
      TableToggle: TableToggleComponent,
      TableDropdownMenu: TableDropdownMenu,
      SignDetailLink: SignDetailLink,
      TableDate: TableDateComponent,
      TableNote: TableNotesComponent,
      TextCellEditor: TextCellEditor,
      NumberCellEditor: NumberCellEditor,
      SelectCellEditor: SelectCellEditor,
      DateCellEditor: DateCellEditor,
      ToggleCellEditor: ToggleCellEditor,
      DepartmentCellEditor: DepartmentCellEditor,
      DepartmentRenderer: DepartmentComponent,
      AutocompleteCellEditor: AutocompleteCellEditor,
      AutocompletesubstrateCellEditor: AutocompletesubstrateCellEditor,
      CustomLoadingCell: LoadingSigns,
      QuantityCell: QuantityCell,
      SetQuantityCell: SetQuantityCell,
      POGCell: POGCell,
      POGEditor: POGEditorCell,
      KitGroupCell: KitGroupCell,
      ProductInfoCell: ProductInfoCell,
      ProductInfoEditor: ProductInfoEditor,
      NotesEditor: NotesCellEditor,
      SignStatusEditor: SignStatusEditor,
      PogCategoriesEditor: PogCategoriesEditor,
      PogCategoriesCell: PogCategoriesCell,
    },
  }

  const onFirstDataRender = () => {
    if (projectSignListSavedFilter) {
      gridApi?.setFilterModel(projectSignListSavedFilter)
    }
    if (projectSignListSortState) {
      gridColumnApi?.applyColumnState({
        state: projectSignListSortState,
        defaultState: { sort: null },
      })
    }
  }

  const onSelectionChange = (event: SelectionChangedEvent) => {
    if (event.api.getSelectedNodes().length > 0) {
      setSelectedRows(
        event.api
          .getSelectedNodes()
          .map((node: RowNode) => new SignResponse(node.data)),
      )
      const status = event.api.getSelectedNodes()[0].data.sign_status
      setSelectedStatus(status)
    } else {
      setSelectedRows([])
    }
  }

  const onSignTemplateChange = (
    sign: any,
    newTemplateName: string,
  ): TableEditorFields => {
    const newTemplate = signTemplateOptions.find(
      (template: SignTemplateResponse) => template.name === newTemplateName,
    )
    const fields = new TableEditorFields({
      ...sign,
      sign_template_info: {
        template_id: newTemplate?.id,
        template_name: newTemplateName,
        size_editable: newTemplate?.size_editable,
        standard_sign: newTemplateName !== 'CUSTOM' && newTemplateName !== 'NA',
      },
      non_retail_item_info: {
        ...sign.non_retail_item_info,
        item_dimensions: {
          width: newTemplate?.finished_width,
          height: newTemplate?.finished_length,
          depth: newTemplate?.finished_depth,
        },
        nominal_dimensions: {
          width: newTemplate?.flat_width,
          height: newTemplate?.flat_length,
          depth: newTemplate?.flat_depth,
        },
      },
    })
    return fields
  }

  const changeDimensions = (
    node: RowNode,
    newTemplate?: SignTemplateResponse,
  ) => {
    node.setDataValue(
      'non_retail_item_info.item_dimensions.width',
      newTemplate ? newTemplate!.finished_width : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.height',
      newTemplate ? newTemplate!.finished_length : '',
    )
    node.setDataValue(
      'non_retail_item_info.item_dimensions.depth',
      newTemplate ? newTemplate!.finished_depth : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.width',
      newTemplate ? newTemplate!.flat_width : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.height',
      newTemplate ? newTemplate!.flat_length : '',
    )
    node.setDataValue(
      'non_retail_item_info.nominal_dimensions.depth',
      newTemplate ? newTemplate!.flat_depth : '',
    )
  }

  const onSignSizeChange = (sign: TableEditorFields, newSignSize: string) => {
    return new TableEditorFields({
      ...sign,
      printing: {
        ...sign.printing,
        print_vendor: 'Target Printing Services',
      },
      distribution: {
        ...sign.distribution,
        kitting_vendor: 'Target Printing Services',
      },
      messaging: {
        ...sign.messaging,
        sign_size: (
          Object.values(SIGN_SIZE_FACETS).find(
            (value: any) => value.label === newSignSize,
          ) as any
        )?.value,
      },
    })
  }

  const onPogCategoryChange = (
    sign: TableEditorFields,
    newPogCategory: string,
  ) => {
    return new TableEditorFields({
      ...sign,
      pog_category: newPogCategory,
      pog_category_id: pogCategoryOptions.find(
        (opt: DropdownOption<any>) => opt.label === newPogCategory,
      )!!.value,
    })
  }

  const onCellValueChange = (event: CellValueChangedEvent) => {
    setSignsModified(true)
    event.data.modified = true
    const editedSignIds = modifiedSignIds
    const editedSigns = modifiedSigns

    if (event.column.getColId() === 'sign_template_info.template_name') {
      const newTemplate = signTemplateOptions.find(
        (template: SignTemplateResponse) => template.name === event.newValue,
      )
      changeDimensions(event.node, newTemplate)
    }

    if (
      event.column.getColId() === 'sign_types' &&
      event.data.sign_template_info.template_name !== 'NA'
    ) {
      setDialogProps({
        headingText: 'Please re-select a sign template.',
        approveButtonText: 'Ok',
        onApprove: () => {
          changeDimensions(event.node, undefined)
          event.node.setDataValue('sign_template_info.template_name', null)
          setDialogProps(new DialogProps())
        },
        onRefuse: () => {
          changeDimensions(event.node, undefined)
          event.node.setDataValue('sign_template_info.template_name', null)
          setDialogProps(new DialogProps())
        },
      })
    }

    if (editedSignIds.includes(event.data.sign_id)) {
      setModifiedSigns(
        editedSigns.map((sign: TableEditedSign) =>
          sign.sign_id === event.data.sign_id
            ? new TableEditedSign({
                action: 'UPDATE',
                fields: event.column
                  .getColDef()
                  .type?.includes('productInfoEditor')
                  ? set(
                      sign.fields,
                      'messaging.item_info[0]',
                      event.data.messaging.item_info[0],
                    )
                  : event.column.getColDef().type?.includes('notesEditor')
                  ? set(sign.fields, event.column.getColId(), {
                      author: userName,
                      content: event.newValue,
                      timestamp: new Date(),
                    })
                  : event.column.getColId() ===
                    'sign_template_info.template_name'
                  ? onSignTemplateChange(sign.fields, event.newValue)
                  : event.column.getColId() === 'messaging.sign_size'
                  ? onSignSizeChange(sign.fields, event.newValue)
                  : event.column.getColId() === 'pog_category'
                  ? onPogCategoryChange(sign.fields, event.newValue)
                  : set(
                      sign.fields,
                      event.column.getColId() === 'sign_types'
                        ? 'sign_type'
                        : event.column.getColId(),
                      event.newValue,
                    ),
                sign_id: sign.sign_id,
              })
            : new TableEditedSign(sign),
        ),
      )
    } else {
      editedSigns.push(
        new TableEditedSign({
          action: 'UPDATE',
          fields: event.column.getColDef().type?.includes('productInfoEditor')
            ? set(
                {},
                'messaging.item_info[0]',
                event.data.messaging.item_info[0],
              )
            : event.column.getColDef().type?.includes('notesEditor')
            ? set({}, event.column.getColId(), {
                author: userName,
                content: event.newValue,
                timestamp: new Date(),
              })
            : event.column.getColId() === 'sign_template_info.template_name'
            ? onSignTemplateChange({}, event.newValue)
            : event.column.getColId() === 'messaging.sign_size'
            ? onSignSizeChange({}, event.newValue)
            : event.column.getColId() === 'pog_category'
            ? onPogCategoryChange({}, event.newValue)
            : set(
                {},
                event.column.getColId() === 'sign_types'
                  ? 'sign_type'
                  : event.column.getColId(),
                event.newValue,
              ),
          sign_id: event.data.sign_id,
        }),
      )
      setModifiedSigns(editedSigns)
    }
    !editedSignIds.includes(event.data.sign_id) &&
      editedSignIds.push(event.data.sign_id)
    setModifiedSignIds(editedSignIds)
    if (event.oldValue !== event.newValue) {
      event.column.getColDef().cellClass = 'ag-cell-modified'
      event.api.refreshCells({
        force: true,
        columns: [event.column.getColId()],
        rowNodes: [event.node],
      })
    }
  }

  const updateSignGroup = (updatedSign: SignResponse) => {
    const editedSignIds = modifiedSignIds
    const editedSigns = modifiedSigns

    setSignsModified(true)
    if (editedSignIds.includes(updatedSign.sign_id)) {
      setModifiedSigns(
        editedSigns.map((sign: TableEditedSign) =>
          sign.sign_id === updatedSign.sign_id
            ? new TableEditedSign({
                action: 'UPDATE',
                fields: set(sign.fields, 'group', updatedSign.group),
                sign_id: sign.sign_id,
              })
            : new TableEditedSign(sign),
        ),
      )
    } else {
      editedSigns.push(
        new TableEditedSign({
          action: 'UPDATE',
          fields: set({}, 'group', updatedSign.group),
          sign_id: updatedSign.sign_id,
        }),
      )
      setModifiedSigns(editedSigns)
    }
    !editedSignIds.includes(updatedSign.sign_id) &&
      editedSignIds.push(updatedSign.sign_id)
    setModifiedSignIds(editedSignIds)
  }

  const onRowDragEnd = (event: any) => {
    const selectedNodes = gridApi?.getSelectedNodes()
    if (selectedNodes && selectedNodes?.length > 0) {
      selectedNodes?.forEach((node: any) => {
        const movingNode = node
        const overNode = event.overNode
        const signGroup = overNode.group ? overNode.key : overNode.data.group
        if (overNode.data && movingNode.data) {
          if (movingNode.data.group !== signGroup) {
            let movingData = movingNode.data
            movingData.modified = true
            movingData.group = signGroup
            updateSignGroup(movingData)
            gridApi?.applyTransaction({ update: [movingData] })
            gridApi?.clearFocusedCell()
            gridApi?.deselectAll()
          }
        }
      })
    } else {
      makeToast({
        ...TOASTER_DEFAULTS,
        type: 'error',
        heading: 'No Sign(s) Selected',
        message: 'Please select at least one sign.',
      })
    }
  }

  const handleAddSignToGroup = (group: string) => {
    onRowDragEnd({
      overNode: {
        data: {
          group: group,
        },
      },
    })
  }

  const onFilterChange = (event: FilterChangedEvent) => {
    setProjectSignListSavedFilter(event.api.getFilterModel())
  }

  const onSortChanged = (event: SortChangedEvent) => {
    setProjectSignListSortState(
      event.columnApi
        .getColumnState()
        .filter((s) => s.sort != null)
        .map((s) => new SortState(s)),
    )
  }

  const sizeColumnsToFit = () => {
    gridApi && gridApi.sizeColumnsToFit()
  }

  const autoSizeAllColumns = () => {
    gridColumnApi && gridColumnApi.autoSizeAllColumns()
  }

  const resetColumnState = () => {
    gridColumnApi && gridColumnApi.resetColumnState()
  }

  const resetAllFilters = () => {
    gridApi && gridApi.setFilterModel({})
    gridColumnApi &&
      gridColumnApi.applyColumnState({ defaultState: { sort: null } })
  }

  const expandAllGroupRows = () => {
    gridApi && gridApi.expandAll()
  }

  const collapseAllGroupRows = () => {
    gridApi && gridApi.collapseAll()
  }

  const fillOperation = useCallback((params: FillOperationParams) => {
    if (params.column.getColId() !== 'messaging.sign_size') {
      return false
    }
    return (
      Object.values(SIGN_SIZE_FACETS).find(
        (value: any) => value.label === params.values[0],
      ) as any
    )?.value
  }, [])

  return (
    <AgGridSignListContext.Provider
      value={{
        gridApi,
        setGridApi,
        gridColumnApi,
        setGridColumnApi,
        rowData,
        setRowData,
        onGridReady,
        gridOptions,
        onFirstDataRender,
        onSelectionChange,
        setSelectedRows,
        selectedRows,
        onCellValueChange,
        updateSignGroup,
        onRowDragEnd,
        handleAddSignToGroup,
        onFilterChange,
        onSortChanged,
        sizeColumnsToFit,
        autoSizeAllColumns,
        resetColumnState,
        resetAllFilters,
        expandAllGroupRows,
        collapseAllGroupRows,
        fillOperation,
      }}
    >
      {children}
    </AgGridSignListContext.Provider>
  )
}

export const useAgGridSignListContext = () => useContext(AgGridSignListContext)

export const AgGridSignListProvider = connect(
  null,
  null,
)(AgGridSignListProviderComponent)
