import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { Column } from 'ag-grid-community'
import { Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { get } from 'lodash'
import { useAgGridSignLibraryContext } from '../../../context/agGridSignLibraryContext'

export interface Props {
  value: any
  column: Column
}

const SelectCellEditor = forwardRef(({ value, column }: Props, ref) => {
  const { editingFacets } = useAgGridSignLibraryContext()!
  const [editValue, setEditValue] = useState(value)
  const refInput = useRef<HTMLInputElement>(null)

  const handleOptions = () => {
    switch (column.getColId()) {
      case 'non_retail_item_info.sign_type':
        return get(editingFacets, 'sign_types')
      case 'non_retail_item_info.unit_of_measure':
        return [
          { value: 'EA', label: 'EA' },
          { value: 'ST', label: 'ST' },
        ]
      case 'non_retail_item_info.printing_info.planned_sides':
        return [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
        ]
      case 'non_retail_item_info.printing_info.number_of_colors':
        return [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
        ]
      default:
        return get(editingFacets, column.getColId(), [])
    }
  }

  useEffect(() => {
    setTimeout(() => refInput?.current?.focus())
  }, [])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return editValue
      },
      isPopup() {
        return true
      },
    }
  })

  return (
    <div className="hc-pa-dense">
      <Grid.Container className="sem_StandardForm error_messaging_enabled">
        <Grid.Item xs={12}>
          <Form.Field
            id="select_cell"
            type="select"
            label={column.getColDef().headerName}
            ref={refInput}
            value={editValue}
            options={handleOptions()}
            onUpdate={(id: string, value: string) => setEditValue(value)}
            size="dense"
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
})

export default SelectCellEditor
