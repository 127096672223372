import { Modal } from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import BulkEditSigns from '../../../../../SignBulkEdit/components/SignBulkEdit'
import { SignDetailsProvider } from '../../../../../SignDetails/context/signDetailsContext'
import { SignFormsProvider } from '../../../../../SignDetails/context/signFormsContext'
import { SignPreviewProvider } from '../../../../../SignDetails/context/signPreviewContext'
import { useProjectDetailsContext } from '../../../../context/projectDetailsContext'
import { useAgGridSignListContext } from '../../context/agGridSignListContext'
import { useProjectSignListContext } from '../../context/projectSignListContext'

const BulkEditModal = () => {
  const { currentProject } = useProjectDetailsContext()!
  const {
    getProjectSigns,
    selectedStatus,
    setSelectedStatus,
    isBulkEditOpen,
    setIsBulkEditOpen,
  } = useProjectSignListContext()!
  const { selectedRows, setSelectedRows } = useAgGridSignListContext()!

  return (
    // WARNING: Canvas v7 - Modal "closeButton" prop has been deprecated.
    <Modal
      className="sem_bulkEditModal"
      headingText="Bulk Edit Signs"
      isVisible={isBulkEditOpen}
      onRefuse={() => setIsBulkEditOpen(false)}
    >
      {isBulkEditOpen && (
        <SignDetailsProvider>
          <SignPreviewProvider>
            <SignFormsProvider>
              <BulkEditSigns
                project={currentProject}
                closeBulkEdit={() => setIsBulkEditOpen(false)}
                selectedSigns={selectedRows}
                setSelectedSigns={setSelectedRows}
                refreshSignList={() =>
                  getProjectSigns(currentProject.project_id)
                }
                selectedStatus={selectedStatus}
                setSelectedStatus={setSelectedStatus}
              />
            </SignFormsProvider>
          </SignPreviewProvider>
        </SignDetailsProvider>
      )}
    </Modal>
  )
}

export default BulkEditModal
