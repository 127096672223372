import { DepartmentLocationGroup } from '../../../models/signs/DistributionRequest.model'

export const NEW_SIGN = 'NEW_SIGN'

export enum SIGN_DETAILS_SIDEBAR {
  GALLERY = 'GALLERY',
  PREVIEW = 'PREVIEW',
}

export const ISM_STRATEGIES = [
  { value: '', label: 'Please select...' },
  { value: 'Value', label: 'Value' },
  { value: 'Brand', label: 'Brand' },
  { value: 'Seasonal', label: 'Seasonal' },
  { value: 'Inspirational', label: 'Inspirational' },
  { value: 'Informational', label: 'Informational' },
  { value: 'Hardware', label: 'Hardware' },
  { value: 'Other', label: 'Other' },
]

export enum SIGN_DETAILS_SECTION {
  INFO = 'info',
  MESSAGING = 'messaging_infos',
  NOTES = 'notes',
  PLACEMENT = 'placement_infos',
  PRICING = 'pricing_infos',
  PRINT = 'printing_infos',
  DISTRIBUTION = 'distribution_infos',
}

export enum SIGN_STATUS {
  NEW = 'New',
  REISSUE = 'Reissue',
  CARRY_FORWARD = 'Carry Forward',
  REMOVE = 'Remove',
}

export enum SIGN_ITEM_STATUS {
  ACTIVE = 'Active',
  IN_DEVELOPMENT = 'In Development',
  OBSOLETE = 'Obsolete',
}

export const SIGN_NOTE_CONTENT_TYPE_OPTIONS = [
  { value: 'PLANNER', label: 'Planner/VC' },
  { value: 'SEPARATOR', label: 'Separator' },
  { value: 'IPP', label: 'IPP Proofing' },
  { value: 'SNP', label: 'POG Placement' },
  { value: 'SPT', label: 'SPT' },
]

export enum MSC_ROW {
  NONE = 'NONE',
  SPECIFIC_STORES = 'SPECIFIC_STORES',
  ALL_STORES = 'ALL_STORES',
  DEPARTMENT = 'DEPARTMENT',
  CLEAR = 'CLEAR',
}

// Shortest possible description length
export const DESCRIPTION_MAX_LENGTH = 27

export const editReasonStatuses = [
  'Signs Finalized',
  'Signs Ready for Kitting',
  'Kits Created',
  'Recreate Kits',
  'Kits Finalized',
  'Recreate Kits (Finalization)',
  'Complete',
]

export const defaultDepartmentLocationGroups = [
  new DepartmentLocationGroup({
    sub_group_ids: [],
  }),
]

export const SIGN_STATUS_OPTION = [
  { value: 'Carry Forward', label: 'Carry Forward' },
  { value: 'Reissue', label: 'Reissue' },
  { value: 'Remove', label: 'Remove' },
]
