export class UserPermissions {
  isBlueprintAdmin: boolean
  isBlueprintEditor: boolean
  isProjectAdmin: boolean
  isProjectEditor: boolean
  isSignAdmin: boolean
  isSignEditor: boolean
  isKitAdmin: boolean
  isKitEditor: boolean
  isSuperAdmin: boolean
  isAgencySeparator: boolean
  isProductVendorOrCatMan: boolean
  isPrintVendor: boolean
  isKitVendor: boolean
  isAssethubVendor: boolean
  isSAPVendor: boolean

  constructor(props: any = {}) {
    this.isBlueprintAdmin = props.isBlueprintAdmin || false
    this.isBlueprintEditor = props.isBlueprintEditor || false
    this.isProjectAdmin = props.isProjectAdmin || false
    this.isProjectEditor = props.isProjectEditor || false
    this.isSignAdmin = props.isSignAdmin || false
    this.isSignEditor = props.isSignEditor || false
    this.isKitAdmin = props.isKitAdmin || false
    this.isKitEditor = props.isKitEditor || false
    this.isSuperAdmin = props.isSuperAdmin || false
    this.isAgencySeparator = props.isAgencySeparator || false
    this.isProductVendorOrCatMan = props.isProductVendorOrCatMan || false
    this.isPrintVendor = props.isPrintVendor || false
    this.isKitVendor = props.isKitVendor || false
    this.isAssethubVendor = props.isAssethubVendor || false
    this.isSAPVendor = props.isSAPVendor || false
  }
}
