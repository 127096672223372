import { SearchRequest } from '../app/SearchRequest.model'

export default class SignExportRequest<T> {
  query: SearchRequest<T>
  export_fields: ExportFields

  constructor(props: any = {}) {
    this.query = new SearchRequest<T>(props.query)
    this.export_fields = new ExportFields(props.export_fields)
  }
}

export class ExportFields {
  /* === Project Sign Info fields ===*/
  'SAP Project ID#'?: string
  'SAP Project Name'?: string
  'Blueprint Name'?: string
  'Sign Status'?: string
  'Project Set Date'?: string
  'Campaign ID'?: string
  'Campaign Type'?: string
  'Finalized Level'?: string
  'Project Type'?: string
  'On POG Sign'?: string
  'File Approved'?: string
  'Project Print Vendor'?: string
  'Kitting Vendor'?: string
  'POG Categories'?: any[]
  'Tie to Product DPCI'?: string
  'Tie to Hardware DPCI'?: string
  'SPT Placement on Shelf'?: string
  'Quantity Per Placement'?: string
  'POG Unit of Measure'?: string
  'POG Placement Notes'?: string
  'POG Exclusions'?: string
  'SPT Notes'?: string
  'Estimated Quantity'?: number
  'Estimated Unit Price'?: number
  'NRSC Quantity'?: number
  'Archway Quantity'?: number
  'Total Quantity'?: number
  'Final Unit Price'?: number
  'Project Created By'?: string
  'Project Created Date'?: string
  'Project Updated By'?: string
  'Project Updated Date'?: string
  /* === Non-Retail Item Default fields ===*/
  'DPCI#'?: string
  'Sign Name'?: string
  'Sign Type'?: string
  'Sign Template'?: string
  'Sign Item Status'?: string
  'Original Set Date'?: string
  'Department'?: string
  'Flat Width'?: string
  'Flat Height'?: string
  'Flat Depth'?: string
  'Finished Width'?: string
  'Finished Height'?: string
  'Sign UPC'?: string
  'Product Vendor'?: string
  'Separator'?: string
  /* === Non-Retail Item Optional fields ===*/
  'Creation/Production Owner'?: string
  'End Date'?: string
  'Pyramid'?: string
  'Division'?: string
  'Finished Depth'?: string
  'Print Vendor'?: string
  'Created Date'?: string
  'Unit of Measure'?: string
  'Set Quantity'?: string
  'Planned Sides'?: string
  'Number of Colors'?: string
  'Substrate 1'?: string
  'Substrate 2'?: string
  'Finishing'?: string
  'Coating'?: string
  'Die Cut'?: string
  'On Press'?: string
  'Pre-Assembled'?: string

  constructor(props: any = {}) {
    this['SAP Project ID#'] = props['SAP Project ID#']
    this['SAP Project Name'] = props['SAP Project Name']
    this['Blueprint Name'] = props['Blueprint Name']
    this['Sign Status'] = props['Sign Status']
    this['Project Set Date'] = props['Project Set Date']
    this['Campaign ID'] = props['Campaign ID']
    this['Campaign Type'] = props['Campaign Type']
    this['Finalized Level'] = props['Finalized Level']
    this['Project Type'] = props['Project Type']
    this['On POG Sign'] = props['On POG Sign']
    this['File Approved'] = props['File Approved']
    this['Project Print Vendor'] = props['Project Print Vendor']
    this['Kitting Vendor'] = props['Kitting Vendor']
    this['POG Categories'] = props['POG Categories']
    this['Tie to Product DPCI'] = props['Tie to Product DPCI']
    this['Tie to Hardware DPCI'] = props['Tie to Hardware DPCI']
    this['SPT Placement on Shelf'] = props['SPT Placement on Shelf']
    this['Quantity Per Placement'] = props['Quantity Per Placement']
    this['POG Unit of Measure'] = props['POG Unit of Measure']
    this['POG Placement Notes'] = props['POG Placement Notes']
    this['POG Exclusions'] = props['POG Exclusions']
    this['SPT Notes'] = props['SPT Notes']
    this['Estimated Quantity'] = props['Estimated Quantity']
    this['Estimated Unit Price'] = props['Estimated Unit Price']
    this['NRSC Quantity'] = props['NRSC Quantity']
    this['Archway Quantity'] = props['Archway Quantity']
    this['Total Quantity'] = props['Total Quantity']
    this['Final Unit Price'] = props['Final Unit Price']
    this['Project Created By'] = props['Project Created By']
    this['Project Created Date'] = props['Project Created Date']
    this['Project Updated By'] = props['Project Updated By']
    this['Project Updated Date'] = props['Project Updated Date']

    this['DPCI#'] = props['DPCI#']
    this['Sign Name'] = props['Sign Name']
    this['Sign Type'] = props['Sign Type']
    this['Sign Template'] = props['Sign Template']
    this['Sign Item Status'] = props['Sign Item Status']
    this['Original Set Date'] = props['Original Set Date']
    this['Department'] = props['Department']
    this['Flat Width'] = props['Flat Width']
    this['Flat Height'] = props['Flat Height']
    this['Flat Depth'] = props['Flat Depth']
    this['Finished Width'] = props['Finished Width']
    this['Finished Height'] = props['Finished Height']
    this['Sign UPC'] = props['Sign UPC']
    this['Product Vendor'] = props['Product Vendor']
    this['Separator'] = props['Separator']
    this['Creation/Production Owner'] = props['Creation/Production Owner']
    this['End Date'] = props['End Date']
    this['Pyramid'] = props['Pyramid']
    this['Division'] = props['Division']
    this['Finished Depth'] = props['Finished Depth']
    this['Print Vendor'] = props['Print Vendor']
    this['Created Date'] = props['Created Date']
    this['Unit of Measure'] = props['Unit of Measure']
    this['Set Quantity'] = props['Set Quantity']
    this['Planned Sides'] = props['Planned Sides']
    this['Number of Colors'] = props['Number of Colors']
    this['Substrate 1'] = props['Substrate 1']
    this['Substrate 2'] = props['Substrate 2']
    this['Finishing'] = props['Finishing']
    this['Coating'] = props['Coating']
    this['Die Cut'] = props['Die Cut']
    this['On Press'] = props['On Press']
    this['Pre-Assembled'] = props['Pre-Assembled']
  }
}
