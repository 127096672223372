import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { Column } from 'ag-grid-community'
import { getVendorOptions } from 'components/App/helpers/vendorHelpers'
import SignLibraryResponse from '../../../../../models/signLibrary/SignLibResponse.model'
import SignTemplateResponse from '../../../../../models/signTemplates/SignTemplateResponse.model'
import { useAgGridSignLibraryContext } from '../../../context/agGridSignLibraryContext'

export interface Props {
  data: SignLibraryResponse
  value: string
  column: Column
}

const AutocompleteCellEditor = forwardRef(
  ({ data, value, column }: Props, ref) => {
    const { signTemplateOptions, getSignTemplatesByType } =
      useAgGridSignLibraryContext()!
    const [editValue, setEditValue] = useState(value)

    const handleOptions = (query: string) => {
      switch (column.getColId()) {
        case 'non_retail_item_info.sign_template_info.template_name':
          return signTemplateOptions.map((template: SignTemplateResponse) => ({
            value: template.name,
            label: template.name,
          }))
        default:
          return getVendorOptions(query, editValue)
      }
    }

    const handleChange = (id: string, vendor: string) => setEditValue(vendor)

    useEffect(() => {
      if (
        column.getColId() ===
        'non_retail_item_info.sign_template_info.template_name'
      ) {
        getSignTemplatesByType(data.non_retail_item_info.sign_type!)
      }
      // eslint-disable-next-line
    }, [data.non_retail_item_info.sign_type, column])

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
        isPopup() {
          return true
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled wide-form">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              options={(query: string) => handleOptions(query)}
              value={{
                value: editValue,
                label: editValue,
              }}
              onUpdate={(id: string, value: any) => {
                if (value) handleChange(id, value.value)
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompleteCellEditor
