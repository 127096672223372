import React, {
  forwardRef,
  useImperativeHandle,
  useState,
  useEffect,
} from 'react'
import { Column } from 'ag-grid-community'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { getVendorOptions } from 'components/App/helpers/vendorHelpers'
import { Grid } from '@enterprise-ui/canvas-ui-react'
import { useProjectSignListContext } from '../../../context/projectSignListContext'
import SignResponse from '../../../../../../../models/signs/SignResponse.model'
import SignTemplateResponse from '../../../../../../../models/signTemplates/SignTemplateResponse.model'

export interface Props {
  data: SignResponse
  value: string
  column: Column
}

const AutocompleteCellEditor = forwardRef(
  ({ data, value, column }: Props, ref) => {
    const { signTemplateOptions, getSignTemplatesByType } =
      useProjectSignListContext()!
    const [editValue, setEditValue] = useState(value ? value : '')

    const handleOptions = (query: string) => {
      switch (column.getColId()) {
        case 'sign_template_info.template_name':
          return signTemplateOptions.map((template: SignTemplateResponse) => ({
            value: template.name,
            label: template.name,
            id: template.id,
          }))
        case 'messaging.disclaimer':
          return [
            {
              id: 'Excludes clearance',
              value: 'Excludes clearance',
              label: 'Excludes clearance',
            },
          ]
        default:
          return getVendorOptions(query, editValue)
      }
    }

    const handleChange = (id: string, value: string) => setEditValue(value)

    useEffect(() => {
      if (column.getColId() === 'sign_template_info.template_name') {
        getSignTemplatesByType(data.sign_type)
      }
    }, [data.sign_type, column]) // eslint-disable-line react-hooks/exhaustive-deps

    useImperativeHandle(ref, () => {
      return {
        getValue() {
          return editValue
        },
      }
    })

    return (
      <div className="hc-pa-dense">
        <Grid.Container className="sem_StandardForm error_messaging_enabled">
          <Grid.Item xs={12}>
            <Autocomplete
              label={column.getColDef().headerName}
              optionHeight="dense"
              options={(query: string) => handleOptions(query)}
              value={{
                value: editValue,
                label: editValue,
              }}
              onUpdate={(id: string, value: any) => {
                if (value) handleChange(id, value.value)
              }}
            />
          </Grid.Item>
        </Grid.Container>
      </div>
    )
  },
)

export default AutocompleteCellEditor
