import React, { useEffect } from 'react'
import { Button, Grid } from '@enterprise-ui/canvas-ui-react'
import SignBulkInfoSection from './SignBulkInfoSection'
import SignPrintSection from '../../SignDetails/components/SignPrintSection'
import SignDistributionSection from '../../SignDetails/components/DistributionSection'
import SignPricingSection from '../../SignDetails/components/SignPricingSection'
import { useSignDetailsContext } from '../../SignDetails/context/signDetailsContext'
import { useSignBulkEditContext } from '../context/signBulkEditContext'
import { useUserContext } from '../../App/context/userContext'
import SignBulkEditFields from '../../../models/signs/SignBulkEditFields.model'
import SignResponse from '../../../models/signs/SignResponse.model'
import { useAppContext } from '../../App/context/appContext'
import { ProjectDetail } from '../../../models/projects/ProjectDetail.model'
import { useProjectDetailsContext } from '../../ProjectDetails/context/projectDetailsContext'
import { useProjectSignListContext } from '../../ProjectDetails/components/ProjectSignList/context/projectSignListContext'
import { SIGN_STATUS } from '../../SignDetails/constants/signDetailsConstants'
import { validateNoBulkExceptions } from '../helpers/bulkEditHelpers'

export interface Props {
  selectedSigns: SignResponse[]
  project: ProjectDetail
  selectedStatus: string
  closeBulkEdit: Function
  refreshSignList: Function
  setSelectedSigns: Function
  setSelectedStatus: Function
}

const SignBulkEdit = ({
  selectedSigns,
  project,
  selectedStatus,
  closeBulkEdit,
  refreshSignList,
  setSelectedSigns,
  setSelectedStatus,
}: Props) => {
  const { refreshNewSignDetails, setIsInProject } = useSignDetailsContext()!
  const { userPermissions } = useUserContext()!
  const { editReasonRequired, currentProject } = useProjectDetailsContext()!
  const { setShowReasonCodeModal, setActionType, reasonCode } =
    useProjectSignListContext()!
  const {
    bulkEditFields,
    saveBulkSigns,
    setBulkEditFields,
    selectedFields,
    setSelectedFields,
  } = useSignBulkEditContext()!
  const { setPageHasChanges } = useAppContext()!

  useEffect(() => {
    refreshNewSignDetails(project.project_id)
  }, [refreshNewSignDetails, project.project_id])

  useEffect(() => {
    setIsInProject(project.project_id !== undefined)
  }, [project.project_id, setIsInProject])

  const save = async () => {
    let fields = new SignBulkEditFields(bulkEditFields)
    if (
      editReasonRequired &&
      validateNoBulkExceptions(fields, currentProject.workflow.status)
    ) {
      setActionType('Bulk')
      setShowReasonCodeModal(true)
    } else {
      await saveBulkSigns(
        selectedSigns.map((sign: SignResponse) => sign.sign_id),
        currentProject.project_id,
        reasonCode,
      )
      closeBulkEdit()
      setSelectedSigns([])
      setSelectedStatus('')
      setSelectedFields([])
      refreshSignList()
      refreshNewSignDetails(project.project_id)
      setPageHasChanges(false)
    }
  }

  const cancel = () => {
    setSelectedSigns([])
    setSelectedStatus('')
    setBulkEditFields({})
    setSelectedFields([])
    closeBulkEdit()
    refreshSignList()
    refreshNewSignDetails(project.project_id)
    setPageHasChanges(false)
  }

  const selectedSignsOnPog = () => {
    return selectedSigns.every((sign) => sign.on_pog_sign)
  }

  const selectedSignsNotOnPog = () => {
    return selectedSigns.every((sign) => !sign.on_pog_sign)
  }

  return (
    <div className="hc-pa-normal">
      <div className="sem_bulkEditInner">
        {(!editReasonRequired ||
          userPermissions.isSignAdmin ||
          userPermissions.isSignEditor) && (
          <SignBulkInfoSection
            selectedStatus={selectedStatus}
            selectedSigns={selectedSigns}
          />
        )}

        {(selectedStatus !== SIGN_STATUS.CARRY_FORWARD ||
          selectedSigns.every((sign: SignResponse) => sign.migrated)) && (
          <div>
            <SignPrintSection isBulkEdit selectedSigns={selectedSigns} />
            <SignDistributionSection
              isBulkEdit
              selectedSignsOnPog={selectedSignsOnPog()}
              selectedSignsNotOnPog={selectedSignsNotOnPog()}
              selectedSigns={selectedSigns}
            />
            <SignPricingSection isBulkEdit selectedSigns={selectedSigns} />
          </div>
        )}
      </div>
      <Grid.Container direction="row-reverse">
        <Grid.Item>
          <Button
            className="sem_bulkEditSave"
            type="primary"
            disabled={selectedFields.length === 0}
            onClick={save}
          >
            Save
          </Button>
        </Grid.Item>
        <Grid.Item>
          <Button
            className="sem_bulkEditSave"
            type="secondary"
            onClick={cancel}
          >
            Cancel
          </Button>
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}

export default SignBulkEdit
