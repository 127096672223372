import React, { createContext, useCallback, useContext, useState } from 'react'
import { connect } from 'react-redux'
import Axios from 'axios'
import FileDownload from 'js-file-download'
import { useEnv } from '@praxis/component-runtime-env'
import {
  HEADER_OBJECT,
  SIGN_SERVICE_API_DOMAIN_URL,
} from '../../App/constants/appConstants'
import {
  SignExportRequest,
  SignLibrarySearchFacets,
} from '../../../models/signLibrary'
import { useFilterContext } from '../../App/context/filterContext'
import {
  defaultNriFieldValues,
  mergeSelectedFields,
} from '../helpers/exportOptionHelpers'
import { useAppContext } from '../../App/context/appContext'
import moment from 'moment'

type ContextType = {
  exportOpen: boolean
  setExportOpen: (isOpen: boolean) => void
  nriSelectedFields: string[]
  setNriSelectedFields: (value: string[]) => void
  allNriSelected: boolean
  setAllNriSelected: (allNriSelected: boolean) => void
  defaultNriSelected: boolean
  setDefaultNriSelected: (defaultNriSelected: boolean) => void
  sptNriSelected: boolean
  setSptNriSelected: (sptNriSelected: boolean) => void
  projectSignSelectedFields: string[]
  setProjectSignSelectedFields: (value: string[]) => void
  allProjectSelected: boolean
  setAllProjectSelected: (allProjectSelected: boolean) => void
  defaultProjectSelected: boolean
  setDefaultProjectSelected: (defaultProjectSelected: boolean) => void
  sptProjectSelected: boolean
  setSptProjectSelected: (sptProjectSelected: boolean) => void
  exportRecords: () => void
}

export const ExportOptionsContext = createContext<ContextType | undefined>(
  undefined,
)

type Props = {
  children: React.ReactNode
}

export const ExportOptionsProviderComponent = ({ children }: Props) => {
  const { setFullPageLoadingMessage } = useAppContext()!
  const { signLibrarySearchRequest } = useFilterContext()!
  const env = useEnv()

  const [exportOpen, setExportOpen] = useState<boolean>(false)
  const [nriSelectedFields, setNriSelectedFields] = useState<string[]>(
    defaultNriFieldValues,
  )
  const [allNriSelected, setAllNriSelected] = useState<boolean>(false)
  const [defaultNriSelected, setDefaultNriSelected] = useState<boolean>(true)
  const [sptNriSelected, setSptNriSelected] = useState<boolean>(false)
  const [projectSignSelectedFields, setProjectSignSelectedFields] = useState<
    string[]
  >([])
  const [allProjectSelected, setAllProjectSelected] = useState<boolean>(false)
  const [defaultProjectSelected, setDefaultProjectSelected] =
    useState<boolean>(false)
  const [sptProjectSelected, setSptProjectSelected] = useState<boolean>(false)

  const exportRecords = useCallback(async () => {
    const exportUrl = `${
      env.apiDomainUrl + SIGN_SERVICE_API_DOMAIN_URL
    }/sign_library/export`

    const exportRequest = new SignExportRequest<SignLibrarySearchFacets>({
      query: signLibrarySearchRequest,
      export_fields: mergeSelectedFields(
        nriSelectedFields,
        projectSignSelectedFields,
      ),
    })

    setFullPageLoadingMessage('Exporting Sign Records...')
    Axios({
      url: exportUrl,
      method: 'POST',
      data: exportRequest,
      ...HEADER_OBJECT,
      responseType: 'blob',
    })
      .then((res) =>
        FileDownload(
          res.data,
          `export_signs_${moment().format('MM_DD_YYYY_HHmm')}.xlsx`,
        ),
      )
      .then(() => setFullPageLoadingMessage(''))
      .catch((err) => {
        console.error('ERROR ===>', err)
        setFullPageLoadingMessage('')
      })
  }, [
    env.apiDomainUrl,
    nriSelectedFields,
    projectSignSelectedFields,
    setFullPageLoadingMessage,
    signLibrarySearchRequest,
  ])

  return (
    <ExportOptionsContext.Provider
      value={{
        exportOpen,
        setExportOpen,
        nriSelectedFields,
        setNriSelectedFields,
        allNriSelected,
        setAllNriSelected,
        defaultNriSelected,
        setDefaultNriSelected,
        sptNriSelected,
        setSptNriSelected,
        projectSignSelectedFields,
        setProjectSignSelectedFields,
        allProjectSelected,
        setAllProjectSelected,
        defaultProjectSelected,
        setDefaultProjectSelected,
        sptProjectSelected,
        setSptProjectSelected,
        exportRecords,
      }}
    >
      {children}
    </ExportOptionsContext.Provider>
  )
}

export const ExportOptionsProvider = connect(
  null,
  null,
)(ExportOptionsProviderComponent)

export const useExportOptionsContext = () => useContext(ExportOptionsContext)
