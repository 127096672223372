import { get } from 'lodash'
import { SIGN_STATUS } from 'components/SignDetails/constants/signDetailsConstants'
import { Department } from '../merchandise/hierarchy/Department.model'
import { Pyramid } from '../merchandise/hierarchy/Pyramid.model'
import DistributionResponse from './DistributionResponse.model'
import MessagingResponse from './MessagingResponse.model'
import PricingResponse from './PricingResponse.model'
import PrintingResponse from './PrintingResponse.model'
import { SignNotes } from './SignNotes.model'

export default class SignResponse {
  /* === metadata === */
  blueprint_id?: string
  blueprint_name?: string
  campaign: BlueprintCampaign
  campaign_id?: string
  created_by: string
  created_date: string
  migrated: boolean
  on_pog_sign: boolean
  project_id?: string
  project_name?: string
  sales_plan: boolean
  sap_project_id: string | null
  set_date: string
  original_set_date: string
  sign_id: string
  updated_by: string
  updated_date: string
  /* === sign info === */
  brand?: string
  department: Department
  description: string
  distribution: DistributionResponse
  messaging: MessagingResponse
  non_retail_item_info: NonRetailItemInfo
  sign_template_info: SignTemplateInfo
  sign_notes: SignNotes
  snp_note: string
  planner_note: string
  ipp_note: string
  separator_note: string
  photography_needed?: boolean
  pricing?: PricingResponse
  separator: string
  printing: PrintingResponse
  sign_creator?: string
  sign_language?: string
  sign_name: string
  sign_status: SIGN_STATUS
  sign_type: string
  unit_of_measure?: string
  set_quantity: number
  workflow: Workflow
  finalized: boolean = false
  ready_to_finalize: boolean = false
  ready_for_kitting: boolean = false
  endcap_type?: string
  ism_strategy?: string
  priority?: string
  program_name?: string
  spt_sign_on_pog: Boolean = false
  third_party_shipping: Boolean = false
  reason_code?: string
  version: string
  can_delete: boolean = false
  modified?: boolean
  group: string
  delete_status?: DeleteStatus
  pog_categories?: any[]
  tie_to_product_dpci?: string
  tie_to_hardware_dpci?: string
  placement_on_shelf?: string
  quantity_per_placement?: string
  pog_unit_of_measure?: string
  pog_exclusions?: string

  constructor(props: any = {}) {
    /* === metadata === */
    this.blueprint_id = props.blueprint_id
    this.blueprint_name = props.blueprint_name
    this.campaign = new BlueprintCampaign(props.campaign)
    this.campaign_id = props.campaign_id
    this.created_by = props.created_by || ''
    this.created_date = props.created_date
    this.migrated = props.migrated || false
    this.on_pog_sign = props.on_pog_sign
    this.project_id = props.project_id
    this.project_name = props.project_name
    this.sales_plan = props.sales_plan || false
    this.sap_project_id = props.sap_project_id
    this.set_date = props.set_date
    this.original_set_date = props.original_set_date
    this.sign_id = props.sign_id || ''
    this.updated_by = props.updated_by || ''
    this.updated_date = props.updated_date
    /* === sign info === */
    this.brand = props.brand
    this.department = new Department(props.department || {})
    this.description = props.description || ''
    this.distribution = new DistributionResponse(props.distribution || {})
    this.messaging = new MessagingResponse(props.messaging || {})
    this.non_retail_item_info = new NonRetailItemInfo(
      props.non_retail_item_info || {},
    )
    this.sign_template_info = new SignTemplateInfo(
      props.sign_template_info || {},
    )
    this.sign_notes = new SignNotes(props.sign_notes || {})
    this.separator_note = props.separator_note || ''
    this.ipp_note = props.ipp_note || ''
    this.planner_note = props.planner_note || ''
    this.snp_note = props.snp_note || ''
    this.photography_needed = props.photography_needed
    this.pricing = new PricingResponse(props.pricing || {})
    this.separator = props.separator || ''
    this.printing = new PrintingResponse(props.printing || {})
    this.sign_creator = props.sign_creator
    this.sign_language = props.sign_language
    this.sign_name = props.sign_name || ''
    this.sign_status = props.sign_status || ''
    this.sign_type = props.sign_type || ''
    this.unit_of_measure = props.unit_of_measure || 'EA'
    this.set_quantity = props.set_quantity
    this.workflow = new Workflow(props.workflow || {})
    this.finalized = !(
      this.workflow.status === '' ||
      this.workflow.status === 'N/A' ||
      this.workflow.status === 'Sign Initiated' ||
      this.workflow.status === 'Ready To Finalize'
    )
    this.ready_to_finalize = this.workflow.status === 'Ready To Finalize'
    this.ready_for_kitting = this.workflow.status === 'Ready To Kit'
    this.endcap_type = props.endcap_type
    this.ism_strategy = props.ism_strategy || ''
    this.priority = props.priority
    this.program_name = props.program_name
    this.third_party_shipping = props.third_party_shipping || false
    this.spt_sign_on_pog = props.spt_sign_on_pog || false
    this.version = props.version || ''
    this.reason_code = props.reason_code || null
    this.can_delete =
      this.workflow.status !== 'Sign Finalized' &&
      ((props.sign_status === SIGN_STATUS.NEW &&
        this.non_retail_item_info.dpci === undefined) ||
        props.sign_status === SIGN_STATUS.CARRY_FORWARD ||
        props.sign_status === SIGN_STATUS.REISSUE ||
        props.sign_status === SIGN_STATUS.REMOVE)
    this.modified = props.modified
    this.group = props.group || 'No Assigned Group'
    this.delete_status = new DeleteStatus(props.delete_status)
    this.pog_categories = props.pog_categories || null
    this.tie_to_product_dpci = props.tie_to_product_dpci || null
    this.tie_to_hardware_dpci = props.tie_to_hardware_dpci || null
    this.placement_on_shelf = props.placement_on_shelf || null
    this.quantity_per_placement = props.quantity_per_placement || null
    this.pog_unit_of_measure = props.pog_unit_of_measure || null
    this.pog_exclusions = props.pog_exclusions || null
  }
}

export class Workflow {
  status: string
  color: string
  reasons?: string[]

  constructor(props: any = {}) {
    this.status = props.status || 'Sign Initiated'
    this.color = props.color || 'indigo'
    this.reasons = props.reasons
  }
}

export class BlueprintCampaign {
  id: string
  name: string
  parent: Boolean = false
  pyramids: Pyramid[]
  type: string

  constructor(props: any = {}) {
    this.id = props.id || ''
    this.name = props.name || ''
    this.parent = props.parent || false
    this.pyramids = get(props, 'pyramids', []).map(
      (pyramid: any) => new Pyramid(pyramid),
    )
    this.type = props.type || ''
  }
}

export class NonRetailItemInfo {
  barcode: string
  dpci: string
  upc: string
  end_date: string
  hardware_info: HardwareInfo
  item_department_code: string
  item_dimensions: Dimensions
  merchandise_classification: MerchandiseClassification
  migrated: Boolean = false
  nominal_dimensions: Dimensions
  on_pog: Boolean = false
  product_vendor: string
  sign_item_status: string
  sign_type: string
  storage_urls: string[]
  tcin: string
  title: string

  constructor(props: any = {}) {
    this.barcode = props.barcode
    this.dpci = props.dpci
    this.upc = props.upc
    this.end_date = props.end_date
    this.hardware_info = new HardwareInfo(props.hardware_info || {})
    this.item_department_code = props.item_department_code || ''
    this.item_dimensions = new Dimensions(props.item_dimensions || {})
    this.merchandise_classification = new MerchandiseClassification(
      props.merchandise_classification || {},
    )
    this.migrated = props.migrated || false
    this.nominal_dimensions = new Dimensions(props.nominal_dimensions || {})
    this.on_pog = props.on_pog || true
    this.product_vendor = props.product_vendor || ''
    this.sign_item_status = props.sign_item_status || ''
    this.sign_type = props.sign_type || ''
    this.storage_urls = props.storage_urls || []
    this.tcin = props.tcin || ''
    this.title = props.title || ''
  }
}

export class HardwareInfo {
  replace_tcins: string[]
  type: string

  constructor(props: any = {}) {
    this.replace_tcins = props.replace_tcins || []
    this.type = props.type || ''
  }
}

export class Dimensions {
  depth?: number
  dimension_unit_of_measure?: string
  height?: number
  width?: number

  constructor(props: any = {}) {
    this.depth = parseFloat(props.depth) || undefined
    this.dimension_unit_of_measure = props.dimension_unit_of_measure
    this.height = parseFloat(props.height) || undefined
    this.width = parseFloat(props.width) || undefined
  }
}

export class MerchandiseClassification {
  class_id: number
  class_name: string
  department_id: number
  department_name: string
  department_display_name: string
  group_id: number
  group_name: string
  group_display_name: string

  constructor(props: any = {}) {
    this.class_id = props.class_id || 0
    this.class_name = props.class_name || ''
    this.department_id = props.department_id || 0
    this.department_name = props.department_name || ''
    this.department_display_name = props.department_name
      ? props.department_name + ' (' + props.department_id + ')'
      : ''
    this.group_id = props.group_id || 0
    this.group_name = props.group_name || ''
    this.group_display_name = props.group_name
      ? props.group_name + ' (' + props.group_id + ')'
      : ''
  }
}

export class PogInfo {
  locations_set: string[]
  quantity: number
  width: number
  height: number
  pog_description: string
  pog_id: string
  status: string
  last_modified_date: string
  excluded: boolean

  constructor(props: any = {}) {
    this.locations_set = props.locations_set || []
    this.quantity = props.quantity || 0
    this.width = (props.width || 0) === 0 ? 0 : props.width / 12
    this.height = props.height || 0
    this.pog_description = props.pog_description || ''
    this.pog_id = props.pog_id || ''
    this.status = props.status || ''
    this.last_modified_date = props.last_modified_date || ''
    this.excluded = props.excluded || false
  }
}

export class DeleteStatus {
  sign_id: string
  status: string
  message: string

  constructor(props: any = {}) {
    this.sign_id = props.sign_id || ''
    this.status = props.status || 'SUCCESS'
    this.message = props.message || 'Unknown issue.'
  }
}

export class SignTemplateInfo {
  template_id: string
  template_name: string
  size_editable: boolean
  standard_sign: boolean

  constructor(props: any = {}) {
    this.template_id = props.template_id || ''
    this.template_name = props.template_name || ''
    this.size_editable = props.size_editable
    this.standard_sign = props.standard_sign
  }
}
