import React, { useState, useEffect, Fragment, useCallback } from 'react'
import {
  Grid,
  Divider,
  Tabs,
  Popover,
  Card,
} from '@enterprise-ui/canvas-ui-react'
import MainSectionHeader from '../../App/components/MainSectionHeader'
import ProjectDetailsTab from './ProjectDetailTab'
import ProjectOverviewCard from './ProjectDetailTab/components/ProjectOverviewCard'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { TabName } from './ProjectDetailTab/constants/projectDetailsConstants'
import { useProjectDetailsContext } from '../context/projectDetailsContext'
import ProjectMilestoneTab from './ProjectMilestoneTab'
import ProjectNotes from './ProjectNotes'
import { useUserContext } from '../../App/context/userContext'
import { NOTES_BUTTON } from '../../App/constants/appConstants'
import ProjectSignListTab from './ProjectSignList'
import ProjectDistributionTab from './ProjectDistributionTab'
import EnterpriseIcon, { CautionFilledIcon } from '@enterprise-ui/icons'

const ProjectDetailsContainer = () => {
  const { id } = useParams()
  const { userInfo } = useUserContext()!
  const {
    currentProject,
    refreshCurrentProject,
    getProjectSummary,
    warningStatuses,
  } = useProjectDetailsContext()!

  const navigate = useNavigate()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  const [activeTab, setActiveTab] = useState(
    query.get('tab') || TabName.DETAILS,
  )
  const messageMap: any = {
    'Recreate Kits': 'Changes required Kits to be re-run.',
    'Recreate Kits (Finalization)': 'Changes required Kits to be re-run.',
    'Kits Modified (Finalization)': 'Changes required Kits to be re-finalized.',
  }

  const loadCurrentProject = useCallback(
    (project_id: string) => {
      refreshCurrentProject(project_id)
      getProjectSummary(project_id)
    },
    [getProjectSummary, refreshCurrentProject],
  )

  useEffect(() => {
    if (id !== undefined) {
      loadCurrentProject(id)
    }
  }, [id, loadCurrentProject])

  return (
    <Fragment>
      <MainSectionHeader
        sectionTitle={currentProject.name}
        sectionEditLink={`/blueprints/${currentProject?.blueprint_id}?tab=PROJECTS`}
        crumbs={[
          { name: 'Project Library', to: '/projects' },
          { name: 'Project Details' },
        ]}
        notesButton={NOTES_BUTTON.PROJECT}
      />
      <ProjectNotes />
      <Card className="hc-pa-normal">
        <Grid.Container>
          <ProjectOverviewCard />
          <Grid.Item className="hc-pb-dense hc-mb-normal" xs={12}>
            <Tabs
              className="hc-mb-none"
              onTabSelect={(event: any, tab: any) => {
                query.delete('kit')
                query.set('tab', tab.name)
                navigate(`${location.pathname}?${query.toString()}`)
                setActiveTab(tab.name)
              }}
              activeTab={activeTab as string}
            >
              <Tabs.Item className="sem_ProjectTab" name={TabName.DETAILS}>
                Project Details
              </Tabs.Item>
              <Tabs.Item
                disabled={!currentProject.sap_project_id}
                title={
                  !currentProject.sap_project_id
                    ? 'Required project fields must be filled out to enable this tab.'
                    : undefined
                }
                className="sem_ProjectTab"
                name={TabName.TIMELINE}
              >
                Project Timeline
              </Tabs.Item>
              <Tabs.Item
                disabled={
                  !currentProject.sap_project_id ||
                  currentProject.milestones.length < 1
                }
                title={
                  !currentProject.sap_project_id ||
                  currentProject.milestones.length < 1
                    ? 'Milestone schedule must be selected and saved to enable this tab.'
                    : undefined
                }
                className="sem_ProjectTab"
                name={TabName.SIGN_LIST}
              >
                Project Sign List
              </Tabs.Item>
              {!userInfo.permissions.isAgencySeparator &&
                !userInfo.permissions.isProductVendorOrCatMan && (
                  <Tabs.Item
                    disabled={
                      !currentProject.sap_project_id ||
                      currentProject.milestones.length < 1
                    }
                    title={
                      !currentProject.sap_project_id ||
                      currentProject.milestones.length < 1
                        ? 'Sign list must be finalized and a kit scenario must be run in order to enable this tab.'
                        : undefined
                    }
                    className="sem_ProjectTab"
                    name={TabName.KITTING}
                  >
                    Kitting / Distribution
                    {warningStatuses.includes(currentProject.status) ? (
                      <Popover
                        content={<p>{messageMap[currentProject.status]}</p>}
                        size="expanded"
                        location="bottom"
                      >
                        <EnterpriseIcon
                          className="hc-ml-dense sem-kitting-alert"
                          color="#fde30c"
                          icon={CautionFilledIcon}
                        />
                      </Popover>
                    ) : null}
                  </Tabs.Item>
                )}
            </Tabs>
            <Divider />
          </Grid.Item>
          <Grid.Item xs={12} className="hc-pt-none">
            {currentProject.project_id && (
              <Fragment>
                {activeTab === TabName.DETAILS ? (
                  <ProjectDetailsTab />
                ) : activeTab === TabName.SIGN_LIST ? (
                  <ProjectSignListTab />
                ) : activeTab === TabName.TIMELINE ? (
                  <ProjectMilestoneTab />
                ) : activeTab === TabName.KITTING ? (
                  <ProjectDistributionTab />
                ) : null}
              </Fragment>
            )}
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Fragment>
  )
}

export default ProjectDetailsContainer
