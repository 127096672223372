import React, { Fragment, useEffect } from 'react'
import {
  Grid,
  ExpandableSection,
  Heading,
  Divider,
  Form,
  Button,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  ErrorIcon,
  MinusIcon,
  PlusIcon,
} from '@enterprise-ui/icons'
import {
  SIGN_SIZE_FACETS,
  TOPPER_BRAND_FACETS,
} from '../../../constants/signMessagingFacets'
import { useSignDetailsContext } from '../../../context/signDetailsContext'
import MessagingForm from './MessagingForm'
import { SIGN_DETAILS_SECTION } from '../../../constants/signDetailsConstants'
import { MessagingItemInfo } from '../../../../../models/signs/MessagingResponse.model'
import ProductInfo from './ProductInfo'
import { useSignFormsContext } from '../../../context/signFormsContext'
import { useAppContext } from 'components/App/context/appContext'
import { useSignPreviewContext } from '../../../context/signPreviewContext'
import { isEmpty } from 'lodash'
import { Autocomplete } from '@enterprise-ui/canvas-ui-react-autocomplete'
import { useUserContext } from 'components/App/context/userContext'
import { isEditable } from 'components/App/helpers/signEditHelper'

const SignMessagingSection = () => {
  const { setPageHasChanges } = useAppContext()!
  const {
    isInProject,
    currentSign,
    expandedSection,
    setExpandedSection,
    modifiedSections,
    addToModifiedSections,
    setOpenSidebar,
    editReasonRequired,
    setShowReasonCodeModal,
    setActionType,
    project,
  } = useSignDetailsContext()!
  const { userPermissions, userCompany, userType } = useUserContext()!
  const { fetchAllProductsInfo } = useSignPreviewContext()!
  const { signPreviewFormik, signMessagingFormik } = useSignFormsContext()!

  // aggregate errors from both forms to show in UI
  const errors = { ...signPreviewFormik.errors, ...signMessagingFormik.errors }

  useEffect(() => {
    if (currentSign.messaging.item_info[0].product_dpci !== '') {
      fetchAllProductsInfo(
        currentSign.messaging.item_info.map(
          (item: MessagingItemInfo) => item.product_dpci,
        ),
      )
    }
  }, [currentSign, fetchAllProductsInfo])

  const headlineHintText =
    signPreviewFormik.values.sign_size === '' ||
    signPreviewFormik.values.sign_size === 'OTHER' ||
    signMessagingFormik.values.headline
      ? ''
      : 'Sign Finalization requires a headline be added.'

  const headlineHighlight = !(
    signPreviewFormik.values.sign_size === '' ||
    signPreviewFormik.values.sign_size === 'OTHER' ||
    signMessagingFormik.values.headline
  )

  const isDisabled = (field: string) => {
    return !isEditable(
      currentSign,
      field,
      userPermissions,
      userCompany,
      userType,
      isInProject,
      project.workflow.status,
      project.roster,
      project,
    )
  }

  const handleChange = (id: string, value: any) => {
    if (id === 'mpp' && value === false) {
      signPreviewFormik.setFieldValue('item_info', [
        signPreviewFormik.values.item_info[0],
      ])
    }
    signPreviewFormik.setFieldValue(id, value)
    addToModifiedSections(SIGN_DETAILS_SECTION.MESSAGING)
    setPageHasChanges(true)
  }

  return (
    <Fragment>
      <Grid.Item
        className="hc-pv-none hc-ph-normal sem_MessagingSection"
        xs={12}
      >
        <ExpandableSection
          padding="none"
          className={`sem_BlueprintFormCard ${
            modifiedSections.includes(SIGN_DETAILS_SECTION.MESSAGING)
              ? 'modified'
              : ''
          }`}
          expanded={expandedSection === SIGN_DETAILS_SECTION.MESSAGING}
          onExpand={() =>
            setExpandedSection(
              expandedSection === SIGN_DETAILS_SECTION.MESSAGING
                ? ''
                : SIGN_DETAILS_SECTION.MESSAGING,
            )
          }
        >
          <Grid.Container justify="space-between" align="center">
            <Grid.Item xs={12}>
              <Heading className="hc-clr-grey01 hc-pl-normal" size={5}>
                Messaging & Product Information
              </Heading>
            </Grid.Item>
          </Grid.Container>
          <ExpandableSection.Content>
            <Divider className="hc-pa-dense" />
            <div className="hc-pa-dense">
              <Grid.Container className={isInProject ? 'hc-mb-normal' : ''}>
                <Grid.Item xs={6}>
                  <Grid.Container
                    justify="center"
                    align="center"
                    className={`sem_StandardForm ${
                      isInProject ? 'error_messaging_enabled' : ''
                    }`}
                  >
                    <Grid.Item xs={true}>
                      <Form.Field
                        type="select"
                        id="sign_size"
                        label="Sign Size"
                        disabled={isDisabled('sign_size')}
                        value={signPreviewFormik.values.sign_size}
                        error={errors.sign_size !== undefined}
                        errorText={errors.sign_size}
                        options={Object.keys(SIGN_SIZE_FACETS)
                          .filter((key) =>
                            SIGN_SIZE_FACETS[key].validSignTypes.includes(
                              currentSign.sign_type,
                            ),
                          )
                          .map((key) => ({
                            value: key,
                            label: SIGN_SIZE_FACETS[key].label,
                          }))}
                        onUpdate={(id: string, value: string) => {
                          if (value !== 'OTHER') {
                            /* Because only "OTHER" size can have more than 4 attributes,
                            remove extra attributes that could have
                            been added when sign size was "OTHER" */
                            handleChange(
                              'item_info[0].attributes',
                              signPreviewFormik.values.item_info[0].attributes.slice(
                                0,
                                4,
                              ),
                            )
                          }
                          // reset MPP to false if sign is no longer SEVEN_BY_ELEVEN
                          if (value !== 'SEVEN_BY_ELEVEN') {
                            handleChange('mpp', false)
                          }
                          handleChange(id, value)
                          setOpenSidebar('')
                        }}
                        hintText={
                          signPreviewFormik.values.sign_size === ''
                            ? 'Sign Size is Required.'
                            : ''
                        }
                        highlight={signPreviewFormik.values.sign_size === ''}
                      />
                    </Grid.Item>
                    <Grid.Item>
                      <Form.Field
                        type="checkbox"
                        id="mpp"
                        label="Multiple Price Point Sign"
                        className="sem_MppChip"
                        checked={signPreviewFormik.values.mpp}
                        disabled={
                          signPreviewFormik.values.sign_size === '' ||
                          !SIGN_SIZE_FACETS[signPreviewFormik.values.sign_size]
                            .allowMpp ||
                          isDisabled('mpp')
                        }
                        onChange={(e: any) => {
                          handleChange(e.target.id, e.target.checked)
                        }}
                      />
                      <div
                        className={`sem_MppError ${
                          errors.mpp ? 'isVisible' : ''
                        }`}
                      >
                        <EnterpriseIcon size="inline" icon={ErrorIcon} />{' '}
                        {errors.mpp}
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={12}>
                      <Form.Field
                        type="textarea"
                        size="dense"
                        id="headline"
                        label="Headline"
                        value={signPreviewFormik.values.headline || ''}
                        hintText={headlineHintText}
                        highlight={headlineHighlight}
                        disabled={isDisabled('headline')}
                        onChange={(e: any) =>
                          handleChange(e.target.id, e.target.value)
                        }
                      />
                    </Grid.Item>
                    {signPreviewFormik.values.mpp ? (
                      signPreviewFormik.values.item_info.map(
                        (info: any, index: number) => (
                          <Fragment key={index}>
                            <Grid.Item xs={12}>
                              <ExpandableSection key={index}>
                                <Grid.Container>
                                  <Grid.Item xs={true}>
                                    <Heading className="hc-clr-grey02" size={6}>
                                      Product {index + 1}
                                    </Heading>
                                  </Grid.Item>
                                  <Grid.Item>
                                    <Tooltip
                                      location="top-left"
                                      content="Add another product"
                                    >
                                      <Button
                                        type="ghost"
                                        size="dense"
                                        iconOnly
                                        id="add_product"
                                        disabled={
                                          signPreviewFormik.values.item_info
                                            .length > 5 ||
                                          isDisabled('add_product')
                                        }
                                        onClick={() =>
                                          signPreviewFormik.setFieldValue(
                                            `item_info`,
                                            [
                                              ...signPreviewFormik.values
                                                .item_info,
                                              new MessagingItemInfo(),
                                            ],
                                          )
                                        }
                                      >
                                        <EnterpriseIcon icon={PlusIcon} />
                                      </Button>
                                    </Tooltip>
                                    <Tooltip
                                      location="top-left"
                                      content="Remove Product"
                                    >
                                      <Button
                                        size="dense"
                                        iconOnly
                                        id="remove_product"
                                        type="destructive"
                                        disabled={
                                          signPreviewFormik.values.item_info
                                            .length === 1 ||
                                          isDisabled('remove_product')
                                        }
                                        onClick={() =>
                                          signPreviewFormik.setFieldValue(
                                            `item_info`,
                                            signPreviewFormik.values.item_info.filter(
                                              (itemInfo, itemIndex) =>
                                                itemIndex !== index,
                                            ),
                                          )
                                        }
                                      >
                                        <EnterpriseIcon icon={MinusIcon} />
                                      </Button>
                                    </Tooltip>
                                  </Grid.Item>
                                </Grid.Container>
                                <ExpandableSection.Content>
                                  <Divider className="hc-pa-dense" />
                                  <div className="hc-pa-dense">
                                    <MessagingForm
                                      handleChange={handleChange}
                                      itemIndex={index}
                                      itemInfo={info}
                                    />
                                  </div>
                                </ExpandableSection.Content>
                              </ExpandableSection>
                            </Grid.Item>
                          </Fragment>
                        ),
                      )
                    ) : (
                      <MessagingForm
                        handleChange={handleChange}
                        itemIndex={0}
                        itemInfo={signPreviewFormik.values.item_info[0]}
                      />
                    )}
                    {signPreviewFormik.values.sign_size ===
                      'ELEVEN_BY_ELEVEN' ||
                    signPreviewFormik.values.sign_size === 'SEVEN_BY_ELEVEN' ? (
                      <Grid.Item xs={12}>
                        <Form.Field
                          type="select"
                          id="topper_brand"
                          label="Topper Brand"
                          value={signPreviewFormik.values.topper_brand || ''}
                          hintText={
                            !signPreviewFormik.values.topper_brand
                              ? 'Please select a topper brand.'
                              : ''
                          }
                          options={TOPPER_BRAND_FACETS}
                          disabled={isDisabled('topper_brand')}
                          onUpdate={(id: string, value: string) =>
                            handleChange(id, value)
                          }
                        />
                      </Grid.Item>
                    ) : null}
                    <Grid.Item xs={12}>
                      <Autocomplete
                        id="disclaimer"
                        label="Disclaimer"
                        hintText="Select or add custom value"
                        allowCustomInputValue={true}
                        disabled={isDisabled('disclaimer')}
                        value={{
                          id: signPreviewFormik.values.disclaimer,
                          value: signPreviewFormik.values.disclaimer,
                          label: signPreviewFormik.values.disclaimer,
                        }}
                        options={[
                          {
                            id: 'Excludes clearance',
                            value: 'Excludes clearance',
                            label: 'Excludes clearance',
                          },
                        ]}
                        onUpdate={(id: string, value: any) => {
                          if (
                            value &&
                            value.value !== signPreviewFormik.values.disclaimer
                          ) {
                            handleChange(id, value.value)
                          } else if (
                            value === null &&
                            signPreviewFormik.values.disclaimer !== null
                          ) {
                            handleChange(id, null)
                          }
                        }}
                      />
                    </Grid.Item>
                    {/*{currentSign.sign_type === 'Value Sign' ? (*/}
                    {/*  <>*/}
                    {/*    <Grid.Item xs={12}>*/}
                    {/*      <Form.Field*/}
                    {/*        type="text"*/}
                    {/*        id="inclusions"*/}
                    {/*        label="Inclusions"*/}
                    {/*        disabled={isDisabled('inclusions')}*/}
                    {/*        value={signPreviewFormik.values.inclusions || ''}*/}
                    {/*        onChange={(e: any) => {*/}
                    {/*          if (e.target.value.length <= 200) {*/}
                    {/*            handleChange(e.target.id, e.target.value)*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*        hintText="Must be no more than 200 characters"*/}
                    {/*      />*/}
                    {/*    </Grid.Item>*/}
                    {/*    <Grid.Item xs={12}>*/}
                    {/*      <Form.Field*/}
                    {/*        type="text"*/}
                    {/*        id="exclusions"*/}
                    {/*        label="Exclusions"*/}
                    {/*        disabled={isDisabled('exclusions')}*/}
                    {/*        value={signPreviewFormik.values.exclusions || ''}*/}
                    {/*        onChange={(e: any) => {*/}
                    {/*          if (e.target.value.length <= 200) {*/}
                    {/*            handleChange(e.target.id, e.target.value)*/}
                    {/*          }*/}
                    {/*        }}*/}
                    {/*        hintText="Must be no more than 200 characters"*/}
                    {/*      />*/}
                    {/*    </Grid.Item>*/}
                    {/*  </>*/}
                    {/*) : null}*/}
                    {currentSign.sign_type === 'Display Sign' &&
                    signPreviewFormik.values.sign_size !== 'OTHER' ? (
                      <Grid.Item xs={12}>
                        <Form.Field
                          type="text"
                          id="logo_url"
                          label="Brand Logo Url"
                          disabled={isDisabled('logo_url')}
                          value={signPreviewFormik.values.logo_url || ''}
                          onChange={(e: any) =>
                            handleChange(e.target.id, e.target.value)
                          }
                        />
                      </Grid.Item>
                    ) : null}
                    {signPreviewFormik.values.sign_size !== '' &&
                    signPreviewFormik.values.sign_size !== 'OTHER' ? (
                      <Fragment>
                        <Grid.Item xs={12}>
                          <Divider className="hc-pv-none" />
                        </Grid.Item>
                        <Grid.Item>
                          <Button
                            type="primary"
                            size="dense"
                            disabled={!isEmpty(signPreviewFormik.errors)}
                            onClick={() => signPreviewFormik.handleSubmit()}
                          >
                            Generate Preview
                          </Button>
                        </Grid.Item>
                      </Fragment>
                    ) : null}
                  </Grid.Container>
                </Grid.Item>
                <Divider
                  className="hc-pv-normal hc-mb-expanded"
                  direction="vertical"
                />
                <Grid.Item xs={6}>
                  <ProductInfo isMpp={signPreviewFormik.values.mpp} />
                </Grid.Item>
                {isInProject ? <Divider className="hc-pa-dense" /> : null}
              </Grid.Container>
              {isInProject ? (
                <Grid.Container direction="row-reverse">
                  <Grid.Item className="hc-pb-dense hc-pt-normal">
                    <Button
                      type="primary"
                      size="dense"
                      disabled={
                        signPreviewFormik.values.sign_size === '' ||
                        !modifiedSections.includes(
                          SIGN_DETAILS_SECTION.MESSAGING,
                        )
                      }
                      onClick={() => {
                        if (editReasonRequired) {
                          setActionType('Msg Edit')
                          setShowReasonCodeModal(true)
                        } else {
                          signMessagingFormik.handleSubmit()
                        }
                      }}
                    >
                      Save Changes
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              ) : null}
            </div>
          </ExpandableSection.Content>
        </ExpandableSection>
      </Grid.Item>
      <Grid.Item className="hc-mv-dense" xs={12}>
        <Divider />
      </Grid.Item>
    </Fragment>
  )
}

export default SignMessagingSection
